import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
import { useTable } from 'react-table';

function Widows(){
    const data = React.useMemo(
        () => [
          {
            name: 'Prisca Ikejimba',
          },
          {
            name: 'Cordelia	Ikeaku',
          },
          {
            name: 'Elizabeth	Umearokwu',
          },
          {
            name: 'Agnes Ogamba',
          },
          {
            name: 'Rose	Okparaji',
          },
          {
            name: 'Cicilia	Ezebude',
          },
          {
            name: 'Felicia	Okonkwo',
          },
          {
            name: 'Grace	Ezeorjiako',
          },
          {
            name: 'Irene	Aka',
          },
          {
            name: 'Sophina	Edward',
          },
          {
            name: 'Mary	Ezeuko',
          },
          {
            name: 'Veronica	Umeadota',
          },
          {
            name: 'Theresa	ElIle',
          },
          {
            name: 'Mercy	Ebo',
          },
          {
            name: 'Anna	Umenwa',
          },
          {
            name: 'Violet	Mbakogu',
          },
          {
            name: 'Ijeoma	Sunday Obi',
          },
          {
            name: 'Marcelina	Oranagwa',
          },
          {
            name: 'Bridget	Okonkwo',
          },
          {
            name: 'Monica	Ezeanokete',
          },
          {
            name: 'Eugenia	Umeh',
          },
          {
            name: 'Felicia	Umezinwa',
          },
          {
            name: 'Regina	Nzediegwu',
          },
          {
            name: 'Alice	Akunekwe',
          },
          {
            name: 'Catherine	Umenduka',
          },
          {
            name: 'Mary	Ebeagwu',
          },
          {
            name: 'Mary	Ezeanyoha',
          },
          {
            name: 'Mary	Uzoabaka',
          },
          {
            name: 'Regina	Umealakeyi',
          },
          {
            name: 'Elizabeth	Umeugochukwu',
          },
          {
            name: 'Susana	Ekemezie',
          },
          {
            name: 'Philomena	Ochiogu',
          },
          {
            name: 'Monica	Chike',
          },
          {
            name: 'Charity	Anaekwe',
          },
          {
            name: 'Debora	Ezechukwu',
          },
          {
            name: 'Beatrice	Ezebude',
          },
          {
            name: 'Marcelina	Oranagwa',
          },
          {
            name: 'Augustina	Ekeneme',
          },
          {
            name: 'Theresa	Obiesie',
          },
          {
            name: 'Mary	Okparaibekwe',
          },
          {
            name: 'Christiana	Okonkwo',
          },
          {
            name: 'Victoria	Ezeigwe',
          },
          {
            name: 'Theresa	Umeugochukwu',
          },
          {
            name: 'Veronica	Ezeobinwa',
          },
          {
            name: 'Cordelia	Onyiudo',
          },
          {
            name: 'Mercy	Orjiekwe',
          },
          {
            name: 'Apolonia	Ifionuu',
          },
          {
            name: 'Medline	Oforbuzo',
          },
          {
            name: 'Evelin	Ezeonyejekwe',
          },
          {
            name: 'Cecilia	Umeaku',
          },
          {
            name: 'Margareth	Anolue',
          },
          {
            name: 'Theresa	Ekesiobi',
          },
          {
            name: 'Justina	Ezeanyim',
          },
          {
            name: 'Veronica	Ezenwajiaku',
          },
          {
            name: 'Caroline	Adurugwu',
          },
          {
            name: 'Theresa	Umeakuka',
          },
          {
            name: 'Anthonia	Okparanzekwe',
          },
          {
            name: 'Christiana	Ofodike',
          },
          {
            name: 'Felicia	Okparaojiego',
          },
          {
            name: 'Maryann	Ezenwugo',
          },
          {
            name: 'Chika	Umealakeyi',
          },
          {
            name: 'Victoria	Nnadum',
          },
          {
            name: 'Veronica	Ezenwanne',
          },
          {
            name: 'Christiana	Umearokwu',
          },
          {
            name: 'Veronica	Dimogbuehi',
          },
          {
            name: 'Cordelia	Ezeigwenma',
          },
          {
            name: 'Christiana	Adibe',
          },
          {
            name: 'Edith	Oranagwa',
          },
          {
            name: 'Grace	Ogige',
          },
          {
            name: 'Agnes	Adawuofor',
          },
          {
            name: 'Chinyere	Onyefurueke',
          },
          {
            name: 'Margareth	Osigwe',
          },
          {
            name: 'Mgbafor	Ezenaike',
          },
          {
            name: 'Maryann	Ezeobiora',
          },
          {
            name: 'Martha	Uzoabaka',
          },
          {
            name: 'Felicia	Ezeakunwanne',
          },
          {
            name: 'Roseline	Nwaokwu',
          },
          {
            name: 'Regina	Uba',
          },
          {
            name: 'Adline	Ofobuzor',
          },
          {
            name: 'Bridget	Ezechukwu',
          },
          {
            name: 'Josephine	Onyezubelu',
          },
          {
            name: 'Regina	Ezenmaduako',
          },
          {
            name: 'Caroline	Adurugwu',
          },
          {
            name: 'Anthonia	Umeakuka',
          },
          {
            name: 'Christiana	Ofodile',
          },
          {
            name: 'Felicia	Okparaojiego',
          },
          {
            name: 'Maryann	Ezenwugo',
          },
          {
            name: 'Caroline	Anokwara',
          },
          {
            name: 'Veronica	Ezenwanne',
          },
          {
            name: 'Christiana	Umearokwu',
          },
          {
            name: 'Chinaelo	Ezeohaneme',
          },
          {
            name: 'Theresa	Ezendakwere',
          },
          {
            name: 'Rose	Orajiaka',
          },
          {
            name: 'Grace	Izuka',
          },
          {
            name: 'Augustina	Okpala',
          },
          {
            name: 'Alice	Izuora',
          },
          {
            name: 'Juliana	Ogidi',
          },
          {
            name: 'Amaka	Ezenduka',
          },
          {
            name: 'Celina	Umeodiegwu',
          },
          {
            name: 'Chioma	Obinali',
          },
          {
            name: 'Chinyere	Umeukeje',
          },
          {
            name: 'Nwakaego	Idimogu',
          },
          {
            name: 'Precilia	Ezendukwere',
          },
          {
            name: 'Rohda	Umeadota',
          },
          {
            name: 'Esther	Mmaju',
          },
          {
            name: 'Catherine	Ezealusiobi',
          },
          {
            name: 'Maurin	Ezeanyika',
          },
          {
            name: 'Peace	Ifeke',
          },
          {
            name: 'Maryann	Oranagwa',
          },
          {
            name: 'Edith	Ogum',
          },
          {
            name: 'Abigail	Ugochukwu',
          },
          {
            name: 'Elizabeth	Emenuga',
          },
          {
            name: 'Virginia	Ibezim',
          },
          {
            name: 'Elizabeth	Uzoh',
          },
          {
            name: 'Grace	Umeohalete',
          },
          {
            name: 'Matrida	Oforbuike',
          },
          {
            name: 'Catherine	Ezekwueme',
          },
          {
            name: 'Fidelia	Umeononankume',
          },
          {
            name: 'Pauline	Umeokoro',
          },
          {
            name: 'Angela	Okparaudu',
          },
          {
            name: 'Rose	Esike',
          },
          {
            name: 'Benedeth	Ezeunyara',
          },
          {
            name: 'Agnes	Adawuofo',
          },
          {
            name: 'Chinyere	Onyefurueke',
          },
          {
            name: 'Margareth	Osigwe',
          },
          {
            name: 'Mgbeafor	Ezennaike',
          },
          {
            name: 'Felicia	Dimonyeka',
          },
          {
            name: 'Addline	Esike',
          },
          {
            name: 'Rose	Umennasie',
          },
          {
            name: 'Pauline	Okparajiaku',
          },
          {
            name: 'Maryann	Ezeobiora',
          },
          {
            name: 'Martha	Uzoabaka',
          },
          {
            name: 'Roseline	Nwaokwu',
          },
          {
            name: 'Felicia	Ezeakunwanne',
          },
          {
            name: 'Felista	Umerika',
          },
          {
            name: 'Theresa	Ezendiokwere',
          },
          {
            name: 'Rose	Orajiaka',
          },
          {
            name: 'Augustina	Okpala',
          },
          {
            name: 'Dora	Okpala',
          },
          {
            name: 'Sophina	Umeozuruora',
          },
          {
            name: 'Alice	Izuora',
          },
          {
            name: 'Maria	Ezeibe',
          },
          {
            name: 'Eucharia	Ezennagha (Rip)',
          },
          {
            name: 'Patricia	Dike',
          },
          {
            name: 'Ngozi	Okwere',
          },
          {
            name: 'Fidelia	Ifediora',
          },
          {
            name: 'Josephine	Umeagwuagu',
          },
          {
            name: 'Ifeoma	Metu',
          },
          {
            name: 'Keziah	Orujekwe',
          },
          {
            name: 'Caroline	Ume',
          },
          {
            name: 'Caroline	Ajaegbu',
          },
          {
            name: 'Maureen	Idika',
          },
          {
            name: 'Janneth	Ezembakwe',
          },
          {
            name: 'Elizabeth	Nsofor',
          },
          {
            name: 'Maryann	Ezembakwe',
          },
          {
            name: 'Chika	Ezeanokete',
          },
          {
            name: 'Catherine	Okparakaniru',
          },
          {
            name: 'Cecilia	Egbo-Ogu',
          },
          {
            name: 'Carity	Ezeamama',
          },
          {
            name: 'Edith	Eze-Eleghiuwa',
          },
          {
            name: 'Agnes	Ezeonyejekwe',
          },
          {
            name: 'Veronica	Ezegwu',
          },
          {
            name: 'Alice	Unegbu',
          },
          {
            name: 'Agnena	Otiocha',
          },
          {
            name: 'Mercy	Ifekanwa',
          },
          {
            name: 'Catherine	Alagbogu',
          },
          {
            name: 'Patricia	Okpalanozie',
          },
          {
            name: 'Eugenia	Umenwanne',
          },
          {
            name: 'Charity	Ezejenje',
          },
          {
            name: 'Grace	Ezeayika',
          },
          {
            name: 'Blessing	Chinwendu',
          },
          {
            name: 'Roseline	Madueke',
          },
          {
            name: 'Monica	Okparannaka',
          },
          {
            name: 'Rose	Ezenwuba',
          },
          {
            name: 'Veronica	Umeuzoegbu',
          },
          {
            name: 'Beatrice	Ikeri',
          },
          {
            name: 'Chinelo	Ezeohaname',
          },
          {
            name: 'Catherine	Akaeme',
          },
          {
            name: 'Patience	Umeodurukwe',
          },
          {
            name: 'Esther	Mmaju',
          },
          {
            name: 'Babyann	Umeodiegwu',
          },
          {
            name: 'Jecentha	Dimeweru',
          },
          {
            name: 'Chinwendu	Ezeibe',
          },
          {
            name: 'Catherine	Ezealusiobi',
          },
          {
            name: 'Maureen	Ezeanyike',
          },
          {
            name: 'Peace	Ifeka',
          },
          {
            name: 'Maryann	Oranagwa',
          },
          {
            name: 'Edith	Ogum',
          },
          {
            name: 'Comfort	Ezebude',
          },
          {
            name: 'Grace	Izuka',
          },
          {
            name: 'Abigail	Ugochukwu',
          },
          {
            name: 'Joy	Ezebude',
          },
          {
            name: 'Mercy	Ezeibekwe',
          },
          {
            name: 'Mary	Umezinwa',
          },
          {
            name: 'Virginia	Ibezim',
          },
          {
            name: 'Apolonia	Umeugoji',
          },
          {
            name: 'Elizabeth	Uzoh',
          },
          {
            name: 'Chinazo	Akachukwu',
          },
          {
            name: 'Gloria	Ojiako',
          },
          {
            name: 'Grace	Omeohalete',
          },
          {
            name: 'Matilda	Ofobuike',
          },
          {
            name: 'Comfort	Onyejieke',
          },
          {
            name: 'Catherine	Ezekwueme',
          },
          {
            name: 'Fidelia	Umeononakume',
          },
          {
            name: 'Pauline	Umeokoro',
          },
          {
            name: 'Angela	Okparaudo',
          },
          {
            name: 'Josephine	Obiagwu',
          },
          {
            name: 'Mercy	Eze',
          },
          {
            name: 'Patience	Umeodurukwe',
          },
          {
            name: 'Sorphina	Unaegbu',
          },
          {
            name: 'Evelin	Umeonaku',
          },
          {
            name: 'Mary	Ojiekwe',
          },
          {
            name: 'Mercy	Umenwokeoma',
          },
          {
            name: 'Joy	Ezebude',
          },
          {
            name: 'Veronica	Ikejimba',
          },
          {
            name: 'Fidelia	Umeuzoegbu',
          },
          {
            name: 'Abigail	Ugochukwu',
          },
          {
            name: 'Margareth	Esike',
          },
          {
            name: 'Comfort	Ubadike',
          },
          {
            name: 'Cordelia	Ezenago',
          },
          {
            name: 'Cecilia	Umeakanne',
          },
          {
            name: 'Dorothy	Okparaunegwu',
          },
          {
            name: 'Christiana	Ezeudogu',
          },
          {
            name: 'Joy	Ezemmaduka',
          },
          {
            name: 'Charity Onyemelukwe',
          },
          {
            name: 'Oluoma	Ezeigwenma',
          },
          {
            name: 'Ngozi	Ezeihejeafor',
          },
          {
            name: 'Theresa	Achusi',
          },
          {
            name: 'Victoria	Agba',
          },
          {
            name: 'Benedict	Agabnere',
          },
          {
            name: 'Josephine	Umeagwuagu',
          },
          {
            name: 'Keziah	Akachukwu',
          },
          {
            name: 'Pauline	Elele',
          },
          {
            name: 'Appolina	Umeakunedu',
          },
          {
            name: 'Ifeoma	Metu',
          },
          {
            name: 'Evelin	Umeolaku',
          },
          {
            name: 'Keziah	Onyekwe',
          },
          {
            name: 'Theresa	Peter Diogu',
          },
          {
            name: 'Onyebaluchi	Virginia',
          },
          {
            name: 'Virgina	Umeh',
          },
          {
            name: 'Maria	Umeonnaku',
          },
          {
            name: 'Janneth	Ezembakwe',
          },
          {
            name: 'Salomy	Nwokeji',
          },
          {
            name: 'Elizabeth	Nsofor',
          },
          {
            name: 'Ngozi	Dimnwanne',
          },
          {
            name: 'Umeozorkwere	Fidelia',
          },
          {
            name: 'Okparaeke	Benedeth',
          },
          {
            name: 'Maryann	Ezembakwe',
          },
          {
            name: 'Mercy	Umeokwonna',
          },
          {
            name: 'Virginia	Adirieje',
          },
          {
            name: 'Theresa	Okafor',
          },
          {
            name: 'Caroline	Ezennaya',
          },
          {
            name: 'Agnes	Ezeigwenma',
          },
          {
            name: 'Catherine	Okparakaniru',
          },
          {
            name: 'Edith	Dike',
          },
          {
            name: 'Cecilia	Ebo-Ogu',
          },
          {
            name: 'Charity	Ezeamama',
          },
          {
            name: 'Edith	Eze Elighiuwa',
          },
          {
            name: 'Maureen	Umenze',
          },
          {
            name: 'Theresa	Osonwanne',
          },
          {
            name: 'Josephine	Obiagwu',
          },
          {
            name: 'Agnes	Ezeonyekwe',
          },
          {
            name: 'Beatrice	Ikerionwu',
          },
          {
            name: 'Veronica	Ezegwu',
          },
          {
            name: 'Alice	Unegbu',
          },
          {
            name: 'Agnes	Otiocha',
          },
          {
            name: 'Agnes	Uzoegbu',
          },
          {
            name: 'Mercy	Ifeakanwa',
          },
          {
            name: 'Catherine	Alagbogu',
          },
          {
            name: 'Patricia	Okpalanozie',
          },
          {
            name: 'Comfort	Ikejimba',
          },
          {
            name: 'Eugenia	Umenwanne',
          },
          {
            name: 'Charity	Ezejerije',
          },
          {
            name: 'Grace	Ezejika',
          },
          {
            name: 'Blessing	Chinwendu',
          },
          {
            name: 'Rosaline	Madueke',
          },
          {
            name: 'Monica	Okparannaka',
          },
          {
            name: 'Rose	Ezenwuba',
          },
          {
            name: 'Veronica	Umeuzoegbu',
          },
          {
            name: 'Beatrice	Ikeri',
          },
        
        ],
        []
      )
    
      const columns = React.useMemo(
        () => [
          {
            Header: 'Name',
            accessor: 'name', // accessor is the "key" in the data
          },
        ],
        []
      )
    
      const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
      } = useTable({ columns, data })



    return(
        <table {...getTableProps()} style={{ border: 'solid 1px blue', width:'100%' }}>
                        <thead>
                            {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                <th
                                    {...column.getHeaderProps()}
                                    style={{
                                    borderBottom: 'solid 3px red',
                                    background: 'aliceblue',
                                    color: 'black',
                                    fontWeight: 'bold',
                                    }}
                                >
                                    {column.render('Header')}
                                </th>
                                ))}
                            </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {rows.map(row => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return (
                                    <td
                                        {...cell.getCellProps()}
                                        style={{
                                        padding: '10px',
                                        border: 'solid 1px gray',
                                        background: 'papayawhip',
                                        }}
                                    >
                                        {cell.render('Cell')}
                                    </td>
                                    )
                                })}
                                </tr>
                            )
                            })}
                        </tbody>
                    </table>
    )
}

export default Widows;