import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";
import CausesHeader from "components/Headers/CausesHeader.js";
import "tachyons";


class Causes extends React.Component {
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <DemoNavbar />
        <main ref="main">
          <CausesHeader />
    

          <section id="youth-scholarships" className="section section-lg">
            <Container className="pb-200">
              <Row className="row-grid align-items-center">
                <Col className="order-md-2" md="6">
                  <img
                    alt="..."
                    className="img-fluid floating"
                    src={require("assets/img/students.png")}
                  />
                </Col>
                <Col className="order-md-1" md="6">
                  <div className="pr-md-5">
                   
                    <h3>YOUTH SCHOLARSHIP 2020/2021 ACADEMIC YEAR BUDGET</h3>
                    <p>
                      In the past decade, we have been committed to changing the lives of many
                      young students by providing them the avenues to obtaining their educational 
                      pursuit at various degree in various universities. We have sponsored over 50 
                      university students since our inception, and we have done this single-handedly by the grace of God.
                    </p>
                    <p>
                      Our beneficiaries are sponsored through-out their university studies, and we cover 
                      their tuition, living and feeding expenses. We have successfully sponsored 23 graduates, and they
                      are in the working class now. We aspire to touch more lives with this means, hence, we 
                      would love you to join us in reaching out to a lot of students and youths facing hardship. 
                    </p>
                    <p>Remember nothing is too small or too big in making an impact. As you help us, God will surely reward you!</p>
                    <Button
                            className="mt-4"
                            color="success"
                            href="/donate"
                          >
                            Donate
                      </Button>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>

          <section id="widow-care" className="section section-lg">
            <Container className="pb-200">
              <Row className="row-grid align-items-center">
                <Col className="order-md-2" md="6">
                  <img
                    alt="..."
                    className="img-fluid floating"
                    src={require("assets/img/widows.png")}
                  />
                </Col>
                <Col className="order-md-1" md="6">
                  <div className="pr-md-5">
                   
                    <h3>WIDOW CARE GROUP - WELFARE BUDGET</h3>
                    <p>
                      Since our inception, over 300 widows are being supported and taken care of 
                      by this foundation. We have provided them food-stuff and clothings since the 
                      inception of this foundation. We aspire to touch more widows facing hardship and neglect,
                      hence, we humbly solicit for your support to continue in this effect, as the time calls 
                      for a desperate need to feed and meet the needs of the widows and women.
                    </p>
                    <Button
                            className="mt-4"
                            color="success"
                            href="/donate"
                          >
                            Donate
                      </Button>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>

          <section id="academy" className="section section-lg">
            <Container className="pb-200">
              <Row className="row-grid align-items-center">
                <Col className="order-md-2" md="6">
                  <img
                    alt="..."
                    className="img-fluid floating"
                    src={require("assets/img/soa.jpg")}
                  />
                </Col>
                <Col className="order-md-1" md="6">
                  <div className="pr-md-5">
                   
                    <h3>SEEDS OF ABRAHAM ACADEMY PROJECT</h3>
                    <p>
                      We are very invested in the future of the young ones. We decided to commence 
                      a school/academy project, that will take in students and teach them to become 
                      future leaders. In these trying times, a lot of children do not go to school or 
                      have parents that can afford the fees. We want to solve this problem. This academy 
                      would ensure that our bright children are not neglected or abandoned. We are on a mission to build
                      a better future and we will need your help and support. 
                    </p>
                    <p>
                      We have already acquired a massive land to begin this project, and with your contribution,
                      we would complete the development. We would ensure that the facilities are all going 
                      to be state of the art. Therefore, we will create an environment where innovation and creativity
                      can thrive. We look forward to welcoming you as a guest to the launching of this academy! 
                    </p>
                    <Button
                            className="mt-4"
                            color="success"
                            href="/donate"
                          >
                            Donate
                      </Button>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>

          <section id="skills-acquisition" className="section section-lg">
            <Container className="pb-200">
              <Row className="row-grid align-items-center">
                <Col className="order-md-2" md="6">
                  <img
                    alt="..."
                    className="img-fluid floating"
                    src={require("assets/img/skills.jpg")}
                  />
                </Col>
                <Col className="order-md-1" md="6">
                  <div className="pr-md-5">
                   
                    <h3>SKILLS ACQUISITION CENTER IN UMUORU - UGA, ANAMBRA</h3>
                    <p>
                      Our Children and Youths are facing financial crisis and there is a scarcity of jobs, which makes things worse.
                      Many youths have the knack for business and entrepreneurship. We do not 
                      want to leave any child uncared for. Therefore, we are undertaking a skills-acquisition
                      training center for students and youths. We want to equip them the skills that would
                      allow them to thrive and excel. This would also give them the ability to fend for themselves 
                      and explore their creativity. We need your support and help in completing
                      the development of this center. We are confident that the lives of the beneficiaries
                      would be positively impacted. 
                    </p>
                    <Button
                            className="mt-4"
                            color="success"
                            href="/donate"
                          >
                            Donate
                      </Button>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>

          <section id="community-development" className="section section-lg">
            <Container className="pb-300">
              <Row className="row-grid align-items-center">
                <Col className="order-md-2" md="6">
                  <img
                    alt="..."
                    className="img-fluid floating"
                    src={require("assets/img/uga.jpg")}
                  />
                </Col>
                <Col className="order-md-1" md="6">
                  <div className="pr-md-5">
                   
                    <h3>COMMUNITY DEVELOPMENT PROJECT: UGA UNITY SQUARE</h3>
                    <p>
                      We are also stretching our scope of impact towards infrastructural development. 
                      We have observed that with our aid, we can provide not only support and help to individuals,
                      but to communities and towns aswell.
                    </p>
                    <p>
                      We have started a project for a rural town - Uga, Anambra. This project covers the construction and development of a state-of-the-art community hall and town square. We have identified
                      this as an infrastructure that would ignite more development and harmony in the community. 
                      Unfortunately this town has been neglected for decades, with no intervention or aid from the government.
                      There are hundreds and thousands of towns/communities that are also in this state. We are working towards
                      doing our best to support these communities. We humbly ask for your help and support to complete this development
                      and to begin new ones for other communities. As you assist us, God will reward you!
                    </p>
                    <Button
                            className="mt-4"
                            color="success"
                            href="/donate"
                          >
                            Donate
                      </Button>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
         
        
         

          <section className="section section-lg pt-lg-100 section-contact-us">
            <Container>
              <Row className="justify-content-center mt--300">
                <Col lg="8">
                  <Card className="bg-gradient-secondary shadow">
                    <CardBody className="p-lg-5">
                      <h4 className="mb-1">Want to work with us?</h4>
                      <p className="mt-0">
                        Your project is very important to us.
                      </p><br />
                      <p>Send us a message today, we look forward to hearing from you soon!</p>
                      <div>
                        <Button
                          block
                          className="btn-round"
                          color="default"
                          size="lg"
                          type="button"
                          target="_blank"
                          href="mailto:info@emmaandgracefoundation.org"
                        >
                          Send Message
                        </Button>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        <SimpleFooter />
      </>
    );
  }
}

export default Causes;
