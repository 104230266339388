import React from "react";
import classnames from "classnames";
import "tachyons";
import "./Donate.css"

// reactstrap components
import {
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  CardFooter
} from "reactstrap";  

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";
import DonateDollar from "./DonateDollar.js";
import DonateNaira from "./DonateNaira.js";





class Donate extends React.Component {
  state = {
    iconTabs: 1,
    plainTabs: 1
  };
  toggleNavs = (e, state, index) => {
    e.preventDefault();
    this.setState({
      [state]: index
    });
  };

  render(){

  
    return (
      <div>
        <DemoNavbar />
        <main>
          <section className="section section-shaped section-lg">
            <div className="shape shape-style-1 bg-gradient-default">
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
            <Container className="pt-lg-7">
              <Row className="justify-content-center">
                <Col lg="9">
                  <Card className="bg-secondary shadow border-0">
                    <CardHeader className="bg-white pb-5">
                      <div className="text-muted text-center mb-3">
                        <h3>Make Donation</h3>
                      </div>
                      <div className="text-center text-muted mb-4">
                        <p>For Bank to Bank Transfer:</p>
                        <p>Naira acc num: 1007284327, Sort-code: 082081021, Bank: Keystone Bank</p>
                        <p>Dollar acc num: 0478436120, Sort-code: 058083956, Swift-code: GTBINGLA, Bank: Guaranty Trust Bank (GTB)</p>
                        </div>
                    </CardHeader>
                    <CardBody className="px-lg-5 py-lg-5">
                      <div className="text-center text-muted mb-4">
                        <p>For Card Payments:</p>
                      </div>
                                
                                {/* Menu */}
                      <div className="nav-wrapper">
                        <Nav
                          className="nav-fill flex-column flex-md-row"
                          id="tabs-icons-text"
                          pills
                          role="tablist"
                        >
                          <NavItem>
                            <NavLink
                              aria-selected={this.state.plainTabs === 1}
                              className={classnames("mb-sm-3 mb-md-0", {
                                active: this.state.plainTabs === 1
                              })}
                              onClick={e => this.toggleNavs(e, "plainTabs", 1)}
                              href="#pablo"
                              role="tab"
                            >
                            Naira Payments
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              aria-selected={this.state.plainTabs === 2}
                              className={classnames("mb-sm-3 mb-md-0", {
                                active: this.state.plainTabs === 2
                              })}
                              onClick={e => this.toggleNavs(e, "plainTabs", 2)}
                              href="#pablo"
                              role="tab"
                            >
                            Dollar Payments
                            </NavLink>
                          </NavItem>
                        </Nav>
                      </div>
                      <Card className="shadow">
                        <CardBody>
                          <TabContent activeTab={"plainTabs" + this.state.plainTabs}>
                            <TabPane tabId="plainTabs1">
                            <DonateNaira />
                              
                            </TabPane>
                            <TabPane tabId="plainTabs2">
                            <DonateDollar />
                            </TabPane>
                          </TabContent>
                        </CardBody>
                      </Card>

                    </CardBody>
                    <CardFooter>
                      <div>
                        <small>Information: If you face difficulty in making payment, use a different browser.</small>
                      </div>
                      <div>
                      <small>An issue might likely come up with Chrome browsers. Thank you for your patience</small>
                      </div>
                    </CardFooter>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        <SimpleFooter />
      </div>
    );
  }
}

export default Donate;
