import React, { useState } from 'react';
import { Document, Page } from 'react-pdf';
import repo from "./EandG-Financial-Statement.pdf"
import {Button} from "reactstrap";


const Report = () => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    
    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    const goToPrevPage = () =>{
        if (pageNumber > 1){
            setPageNumber(pageNumber - 1 );
        }
    }

  
    const goToNextPage = () =>{
        if (pageNumber < numPages){
            setPageNumber(pageNumber + 1 );
        }
    }


    return (
        <div className="tc">
            <nav>
                <Button
                 className="mt-4"
                 color="default"
                 onClick={goToPrevPage}>Prev</Button>

                <Button 
                 className="mt-4"
                 color="default"
                 onClick={goToNextPage}>Next</Button>
            </nav><br />
            <Document
            file={repo}
            onLoadSuccess = {onDocumentLoadSuccess}
            >
                <Page pageNumber = {pageNumber} />
            </Document>

    <p>Page {pageNumber} of {numPages}</p>
        </div>
    );

}

export default Report;