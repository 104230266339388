import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
import { useTable } from 'react-table';

function Undergrad(){
    const data = React.useMemo(
        () => [
          {
            name: 'Umeodiegwu Chukwuemerie Chinedu',
            school: 'ESUT/Law',
            phone: '08032901069'
          },
          {
            name: 'Umeadota Uchenna Eucharia',
            school: 'Futo/Pub. health tech.',
            phone: '08188073289'
          },
          {
            name: 'Umeugochukwu Maryann Chinagorom',
            school: 'UNN/Radiography',
            phone: '07060571477'
          },
          {
            name: 'Ezeifeoma Stephen Chinedu',
            school: 'Ojukwu/Pol. Science',
            phone: '09037453409'
          },
          {
            name: 'Anozie Ogechi Prisca',
            school: 'Ojukwu/Econ. Education',
            phone: '07065234113'
          },
          {
            name: 'Nnadum Chinecherem Samuel',
            school: 'Ojukwu/Architecture',
            phone: '08106256591'
          },
          {
            name: 'Umeaku Benson Ebuka',
            school: 'Ojukwu/Civil Engineering',
            phone: '08068565347'
          },
          {
            name: 'Ezejiaku Onyinyechukwu F',
            school: 'Poly. Oko/Mass Comm.',
            phone: '08120641412'
          },
          {
            name: 'Umeukeje Confidence',
            school: 'Poly. Oko/Lib & Info. Tech',
            phone: '07031567004'
          },
          {
            name: 'Umeh Eberechi Success',
            school: 'Odumegwu Ojukwu/Micro.Bio',
            phone: '08101791090'
          },
          {
            name: 'Ebenezar Chinaelo Grace',
            school: 'Uniport/Animal & Environ. Sc.',
            phone: '08138320720'
          },
          {
            name: 'Umeozuluora Chigozie',
            school: '',
            phone: '09069050063'
          },
          {
            name: 'Umeh Frank Chibueze',
            school: 'UNN/Marketing',
            phone: '07034348267'
          },
          {
            name: 'Chinonye Umechukwu Perpetua',
            school: 'UNN/Archeology & Tourism',
            phone: '08062210273'
          },
          {
            name: 'Umennaihe Chinecherem Rose',
            school: '',
            phone: '08106189829'
          },
          {
            name: 'Nwokeji Chioma',
            school: 'Poly. Oko/Econs',
            phone: '09030516196'
          },
          {
            name: 'Ezejiaku Mmasichukwu Cecilia',
            school: '',
            phone: '08164134975'
          },
          {
            name: 'Ezenwajiaku Mary C.',
            school: '',
            phone: ''
          },
          {
            name: 'Ogige Emmanuel Onyeka	',
            school: '',
            phone: ''
          },
        ],
        []
      )
    
      const columns = React.useMemo(
        () => [
          {
            Header: 'Name',
            accessor: 'name', // accessor is the "key" in the data
          },
          {
            Header: 'School/Course',
            accessor: 'school',
          },
          {
            Header: 'Phone',
            accessor: 'phone',
          },
        ],
        []
      )
    
      const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
      } = useTable({ columns, data })



    return(
        <table {...getTableProps()} style={{ border: 'solid 1px blue', width:'100%' }}>
                        <thead>
                            {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                <th
                                    {...column.getHeaderProps()}
                                    style={{
                                    borderBottom: 'solid 3px red',
                                    background: 'aliceblue',
                                    color: 'black',
                                    fontWeight: 'bold',
                                    }}
                                >
                                    {column.render('Header')}
                                </th>
                                ))}
                            </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {rows.map(row => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return (
                                    <td
                                        {...cell.getCellProps()}
                                        style={{
                                        padding: '10px',
                                        border: 'solid 1px gray',
                                        background: 'papayawhip',
                                        }}
                                    >
                                        {cell.render('Cell')}
                                    </td>
                                    )
                                })}
                                </tr>
                            )
                            })}
                        </tbody>
                    </table>
    )
}

export default Undergrad;