import React, {useState} from "react";
import {PaystackButton} from "react-paystack";
import "tachyons";


const DonateDollar = () => {


  const publicKey = "pk_live_ca7828e6316117f28391c0bc6f8e4a98cab7d529"
  const [amount, setAmount] = useState()
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")


  const componentProps = {
    email,
    amount,
    metadata: {
      name
    },
    publicKey,
    text: "Pay Now",
    currency: "USD",
    onSuccess: () => alert("Thank you for your donation, We appreciate!! Come back Soon!"),
    onClose: () => alert("Thank you for considering to donate, we hope you do next time  :)")
  }

    return (
        <div className="justify-content-center">
        <div className="">
                      <fieldset id="sign_up" className="ba b--transparent ph0 mh0">
                          <div className="mt3">
                              <label className="db fw6 lh-copy f6" htmlFor="name">Name</label>
                              <input className="pa2 input-reset ba bg-transparent w-100" 
                              type="Name" name="Name"  id="Name" 
                              onChange = {(e) => setName(e.target.value)}/>
                          </div>
                          <div className="mt3">
                              <label className="db fw6 lh-copy f6" htmlFor="email-address">Email</label>
                              <input className="pa2 input-reset ba bg-transparent w-100" 
                              type="email" name="email-address"  id="email-address" 
                              onChange = {(e) => setEmail(e.target.value)}/>
                          </div>
                          <div className="mv3">
                              <label className="db fw6 lh-copy f6" htmlFor="amount">Amount (in Dollar)</label>
                              <input className="b pa2 input-reset ba bg-transparent w-100" 
                              type="amount"  
                              onChange= {(e) => setAmount(Number(e.target.value)*100)}/>
                          </div>
                          
                      </fieldset>
                      <PaystackButton {...componentProps} />
                  </div>
      </div>
    );
  
}

export default DonateDollar;