import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
import { useTable } from 'react-table';

function Graduates(){
    const data = React.useMemo(
        () => [
          {
            name: 'Okoroji Chisom Loretta',
            school: 'UNN/ Biochemistry',
            phone: '07038397044'
          },
          {
            name: 'Udokwu Chinyere',
            school: 'Sch. of Catering Nnewi',
            phone: '08139553506'
          },
          {
            name: 'Umeh Victor Oluebube',
            school: 'Unizik/Chemical Eng.',
            phone: '07065446239'
          },
          {
            name: 'Ezeonyejekwe Chidogo Lucy',
            school: 'Ojukwu Uni./MicroBio.',
            phone: '08037544460'
          },
          {
            name: 'Umeunegbu Pascal C.',
            school: 'Unizik/Mathematics',
            phone: '07034498536'
          },
          {
            name: 'Umeugoji Ijeoma Somadina',
            school: 'Ebonyi state/Ind. Chem.',
            phone: '08138590756'
          },
          {
            name: 'Onwuaso Chimezie Emmanuel',
            school: 'Unizik/Econs. Edu.',
            phone: '08138590756'
          },
          {
            name: 'Chukwudebere Stephen C.',
            school: 'Ojukwu Uni/Edu.Econs',
            phone: '07039160554'
          },
          {
            name: 'Akubuike Jacinta Ngozi',
            school: 'Futo/Project Man. tech.',
            phone: '08060548283'
          },
          {
            name: 'Ezemmaduekwe Chisomaga',
            school: 'Ojukwu Uni/ MicroBio.',
            phone: '08162482110'
          },
          {
            name: 'Umeohana Oluchukwu Samuel',
            school: 'Futo/Environ. tech.',
            phone: '08165531370'
          },
          {
            name: 'Nwosu Nzube',
            school: 'Ojukwu/Industrial chem.',
            phone: '08167669345'
          },
          {
            name: 'Onoja Comfort',
            school: 'Benue state/Psychology',
            phone: '08133720045'
          },
          {
            name: 'Umeh Ebuka Joseph',
            school: 'UNN/Political Sci.',
            phone: '08162262894'
          },
          {
            name: 'Akaligwo Anthonia Chikaodiri',
            school: 'Ojukwu/Bio.Science',
            phone: '08142140586'
          },
          {
            name: 'Ezennagha Amarachi Blessing',
            school: 'Uniben/Early Childhood Edu.',
            phone: '08160864214'
          },
          {
            name: 'Ezeanokete Nicholas Onyedika',
            school: 'Unical/Med. lab. sci.',
            phone: '08143556822'
          },
          {
            name: 'Umeh Nnamdi Kingsley',
            school: 'UNN/Computer Sci.',
            phone: '08067642179'
          },
          {
            name: 'Umeugoji Valentine Chidubem',
            school: 'Ojukwu/Econs Edu.',
            phone: '08127445253'
          },
          {
            name: 'Mmaju Ikenna Vitalis',
            school: 'Unizik/Psychology',
            phone: '07035888331'
          },
          {
            name: 'Onyebaluchi Emmanuel Chinonso',
            school: 'Ojukwu/Biochem',
            phone: '08162653263'
          },
          {
            name: 'Ezeobioha Chigozie Hycenth',
            school: 'Ojukwu/Biochem',
            phone: '08102760904'
          },
        ],
        []
      )
    
      const columns = React.useMemo(
        () => [
          {
            Header: 'Name',
            accessor: 'name', // accessor is the "key" in the data
          },
          {
            Header: 'School/Course',
            accessor: 'school',
          },
          {
            Header: 'Phone',
            accessor: 'phone',
          },
        ],
        []
      )
    
      const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
      } = useTable({ columns, data })



    return(
        <table {...getTableProps()} style={{ border: 'solid 1px blue', width:'100%' }}>
                        <thead>
                            {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                <th
                                    {...column.getHeaderProps()}
                                    style={{
                                    borderBottom: 'solid 3px red',
                                    background: 'aliceblue',
                                    color: 'black',
                                    fontWeight: 'bold',
                                    }}
                                >
                                    {column.render('Header')}
                                </th>
                                ))}
                            </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {rows.map(row => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return (
                                    <td
                                        {...cell.getCellProps()}
                                        style={{
                                        padding: '10px',
                                        border: 'solid 1px gray',
                                        background: 'papayawhip',
                                        }}
                                    >
                                        {cell.render('Cell')}
                                    </td>
                                    )
                                })}
                                </tr>
                            )
                            })}
                        </tbody>
                    </table>
    )
}

export default Graduates;