import React from "react";

// reactstrap components
import { Button, Card, Container, Row, Col } from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";

class Admin extends React.Component {
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <DemoNavbar />
        <main className="profile-page" ref="main">
          <section className="section-profile-cover section-shaped my-0">
            {/* Circles background */}
            <div className="shape shape-style-1 shape-default alpha-4">
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>
          <section className="section">
            <Container>
              <Card className="card-profile shadow mt--300">
                <div className="px-4">
                  <Row className="justify-content-center">
                    <Col className="order-lg-2" lg="3">
                      <div className="card-profile-image">
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          <img
                            alt="..."
                            className="rounded-circle"
                            src={require("assets/img/admin.jpg")}
                          />
                        </a>
                      </div>
                    </Col>
                    
                  </Row>
                  <br /><br /><br /><br />
                  <div className="text-center mt-5">
                    <h3>
                      Barr. Didi-Blessing Ezenwafor
                    </h3>
                    <div className="h6 font-weight-300">
                      <i className="ni location_pin mr-2" />
                      Abuja, Nigeria
                    </div>
                    <div className="h6 mt-4">
                      <i className="ni business_briefcase-24 mr-2" />
                      Administration / Communications
                    </div>
                    <div>
                      <i className="ni education_hat mr-2" />
                        <p>LL. B. Law, Baze University</p>
                    </div>
                  </div>
                  <div className="mt-5 py-5 border-top text-center">
                    <Row className="justify-content-center">
                      <Col lg="9">
                            <p >
                                Didi blessing was born to Apostle Dan and Deaconess Julia Onyekwere in the south-eastern part of Nigeria.
                                Her maternal grandfather was the first priest in her home-town. He established the church known as African Church, which 
                                is the current Salvation Army Church. He performed the work of God by returning many lost ones back to jesus Christ. He 
                                went to different places that were considered evil, and he delivered it to God and churches were built there. 
                                Her mum, Julia Onyekwere, was an evangelist that preached the word of God in their home-town. 
                            </p>
                            <p >
                                Given the background of Evang. Blessing's parent, from a tender age of 9 - 11 years, she started to follow her mum as early as 3:00am
                                for morning cry and evangelism. She lead in praises and worship, while her mother would preach. They would continue to do this from 
                                place to place. Her talent for music was also inspired from her parent. Her father was a gifted instrumentalist and her mum composed 
                                music. She eventually became the music director of her mum's outreach. Due to her massive involvment with her mum's outreach, she witnessed
                                the great power of God that convinced her that Jesus is real. Her mum was regarded as a holy woman who loved God passionately. There 
                                was no pretence or hypocrisy in her worship to Jehovah, and these built up her faith in Jesus Christ. Her mum prayed all
                                her children into the ministry and each of them are doing the work of the kingdom in their different field of calling. 
                            </p>
                            <p > 
                                Evang Didi Blessing was a very brilliant student and studied pure sciences in secondary school. She did exeptionally well in her WAEC exams. She wanted
                                to study pharmarcy, but her dad did not have enough money to train all of his children. Unfortunately, she could not continue her studies. 
                                By God's mercy upon her, she was able to continue her studies after marriage. Her and her family moved to abuja in 2002. She enrolled at the prestigious Baze University in Abuja.
                                She successfully earned her bachelors degree in Law and went on to complete her Law School. She did her Call-to-Bar ceremony and became an certified 
                                Lawyer. 
                            </p>
                            <p>
                                She continues to serve God and perform great sacrificial service towards the Kingdom. 
                                She serves as our Admin officer and her contributions are well appreciated. We love her so much!
                            </p>

                        <br />
                        <div className="mt-3">
                        <Button
                          className="btn-icon-only rounded-circle"
                          color="danger"
                          href="https://www.instagram.com/blessingpraises/"
                          target="_blank"
                          
                        >
                          <i className="fa fa-instagram" />
                        </Button>
                        <Button
                          className="btn-icon-only rounded-circle ml-1"
                          color="danger"
                          href="https://www.facebook.com/people/Didi-Blessing/100009065027743"
                          target="_blank"
                        >
                          <i className="fa fa-facebook" />
                        </Button>
                        
                      </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Card>
            </Container>
          </section>
        </main>
        <SimpleFooter />
      </>
    );
  }
}

export default Admin;
