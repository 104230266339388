import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Container,
  Row,
  UncontrolledCarousel,
  Col
} from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";
import GalleryHeader from "components/Headers/GalleryHeader.js";
import Iframe from 'react-iframe';
import "tachyons";

const items = [
  {
    src: require("assets/img/anniversary.png"),
    altText: "Emma and Grace foundation Celebrates 6-years anniversary",
    caption: "Emma and Grace foundation Celebrates 6-years anniversary",
    header: ""
  },
  {
    src: require("assets/img/emma-and-grace-1.jpg"),
    altText: "Our Chairman and his wife with the student beneficiaries",
    caption: "Our Chairman and his wife with the student beneficiaries",
    header: ""
  },
  {
    src: require("assets/img/emma-and-grace-2.jpg"),
    altText: "Our chairman and wife being prayed for by the widows",
    caption: "Our chairman and wife being prayed for by the widows",
    header: ""
  },
  {
    src: require("assets/img/gallery1.png"),
    altText: "Guests during the anniversary celebration",
    caption: "Guests during the anniversary celebration",
    header: ""
  },
  {
    src: require("assets/img/Gallery2.png"),
    altText: "Our Chairman's sister with a guest during the anniversary",
    caption: "Our Chairman's sister with a guest during the anniversary",
    header: ""
  },
  {
    src: require("assets/img/soa.jpg"),
    altText: "Seeds of Abraham Academy",
    caption: "Seeds of Abraham Academy",
    header: ""
  },
  {
    src: require("assets/img/students.png"),
    altText: "Student benficiaries",
    caption: "Student benficiaries",
    header: ""
  },
  {
    src: require("assets/img/uga.jpg"),
    altText: "Construction of Uga Town hall and square",
    caption: "Construction of Uga Town hall and square",
    header: ""
  },
  {
    src: require("assets/img/skills.jpg"),
    altText: "Skills acquisition center",
    caption: "Skills acquisition center",
    header: ""
  }
];

class Gallery extends React.Component {
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <DemoNavbar />
        <main ref="main">
          <GalleryHeader />
    
          <section className="section section-lg">
            <Container>
            <h3>Videos</h3><br /><br />
              <Row className="justify-content-center">
                
                <Col className="order-md-1" md="15">
                  <div className="pr-md-5">
                   
                   
                    <Iframe url="https://www.youtube.com/embed/eMb0EDKftK4"
                      width="400px"
                      height="250px"
                      id="myId"
                      className="myClassname"
                      display="initial"
                      position="relative"/>

                  <br /><br />
                  </div>
                </Col>

                <Col className="order-md-1" md="15">
                  <div className="pr-md-5">
                   
                    <Iframe url="https://www.youtube.com/embed/oUgntYW0kSg"
                      width="400px"
                      height="250px"
                      id="myId"
                      className="myClassname"
                      display="initial"
                      position="relative"/>

                  <br /><br />
                  </div>
                </Col>
              </Row>
            </Container>
          </section>


          <section className="section section-shaped">
          <div className="shape shape-style-1 shape-default">
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
          </div>
          <Container className="py-md pb-300">
          <h1 className="text-white font-weight-light">
                  Pictures
                </h1> <br />
            <Row className="justify-content-center">
              <Col className="mb-lg-auto" lg="8">
                <div className="rounded shadow-lg overflow-hidden transform-perspective-right">
                  <UncontrolledCarousel items={items} />
                </div>
              </Col>
            </Row>
          </Container>
          {/* SVG separator */}
          <div className="separator separator-bottom separator-skew">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon className="fill-white" points="2560 0 2560 100 0 100" />
            </svg>
          </div>
        </section>
        
         

        <section className="section section-lg pt-lg-100 section-contact-us">
            <Container>
              <Row className="justify-content-center mt--300">
                <Col lg="8">
                  <Card className="bg-gradient-secondary shadow">
                    <CardBody className="p-lg-5">
                      <h4 className="mb-1">Want to work with us?</h4>
                      <p className="mt-0">
                        Your project is very important to us.
                      </p><br />
                      <p>Send us a message today, we look forward to hearing from you soon!</p>
                      <div>
                        <Button
                          block
                          className="btn-round"
                          color="default"
                          size="lg"
                          type="button"
                          target="_blank"
                          href="mailto:info@emmaandgracefoundation.org"
                        >
                          Send Message
                        </Button>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        <SimpleFooter />
      </>
    );
  }
}

export default Gallery;
