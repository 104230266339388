import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";
import ParHeader from "components/Headers/ParHeader.js";
import "tachyons";


class Patriach extends React.Component {
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <DemoNavbar />
        <main ref="main">
          <ParHeader />
    
          <section className="section section-lg">
            <Container className="pb-300">
              <Row className="row-grid align-items-center">
                <Col className="order-md-2" md="6">
                  <img
                    alt="..."
                    className="img-fluid floating"
                    src={require("assets/img/emmaandgrace.png")}
                  /><br /><br />
                  <p>Pa Emmanuel Eyiuche Nnodumele Ezenwafor & his wife, Mama Grace.</p>
                </Col>
                <Col className="order-md-1" md="6">
                  <div className="pr-md-5">
                   
                    <h3>Our Partriach</h3>
                    <p>
                    The late Emmanuel Eyiuche Nnodumele Ezenwafor was born in the year 1918
                     – an era Western Education and Christianity were very unpopular in this part of the world. 
                     When he came of age, he was converted to Christianity and he managed to enroll 
                     in a Missionary School of the Irish Fathers. He became a lone ranger since he
                      was the first and the only one that broke the jinx of illiteracy in his 
                      immediate locality.
                    </p>
                    
                    <p>
                    He lived a modest life. He was lowly and unassuming, yet a great philanthropist. 
                    He neither blew his trumpet nor allowed anybody to do so for him. Some of the 
                    significant philanthropic work he did indirectly to many families, relations, 
                    strangers and even his avowed enemies are up till date not appreciated or ascribed to him. 
                    In fact many people that benefitted from his benevolence never realized that he was actually the one 
                    behind it. So he deliberately did all he could as a service to God.
                    </p>
                    
                    <p>
                    He was particularly interested in Western Education,
                     hence he went out of his way to encourage and sponsor several 
                     young people when going to school was yet a luxury. 
                     The peculiar trait of selflessness and liberality is thus replicated in his 
                     lineage; hence the FOUNDATION is named after him and his spouse, Grace.
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>


         
        
         

          <section className="section section-lg pt-lg-100 section-contact-us">
            <Container>
              <Row className="justify-content-center mt--300">
                <Col lg="8">
                  <Card className="bg-gradient-secondary shadow">
                    <CardBody className="p-lg-5">
                      <h4 className="mb-1">Want to work with us?</h4>
                      <p className="mt-0">
                        Your project is very important to us.
                      </p><br />
                      <p>Send us a message today, we look forward to hearing from you soon!</p>
                      <div>
                        <Button
                          block
                          className="btn-round"
                          color="default"
                          size="lg"
                          type="button"
                          target="_blank"
                          href="mailto:info@emmaandgracefoundation.org"
                        >
                          Send Message
                        </Button>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        <SimpleFooter />
      </>
    );
  }
}

export default Patriach;
