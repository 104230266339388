/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/font-awesome/css/font-awesome.min.css";
import "assets/scss/argon-design-system-react.scss?v1.1.0";


import Contact from "views/examples/Contact.js";
import Beneficiaries from "views/examples/Beneficiaries.js";
import Donate from "views/examples/Donate.js";
import Gallery from "views/examples/Gallery.js";
import Causes from "views/examples/Causes.js";
import Blog from "views/examples/Blog.js";
import Landing from "views/examples/Landing.js";
import Login from "views/examples/SocialPlatform/Login.js";
import Profile from "views/examples/Profile.js";
import Register from "views/examples/SocialPlatform/Register.js";
import Patriach from "views/examples/Patriach.js";
import OurStory from "views/examples/OurStory.js";
import Admin from "views/examples/Team/Admin.js";
import Director from "views/examples/Team/Director.js";
import Exdirector from "views/examples/Team/Exdirector.js";
import President from "views/examples/Team/President.js";
import Secretary from "views/examples/Team/Secretary.js";
import FinancialReport from "views/examples/FinancialReport.js";
import SocialPlatform from "views/examples/SocialPlatform.js"


ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/" exact render={props => <Landing {...props} />} />
     
      <Route
        path="/social-platform"
        exact
        render={props => <SocialPlatform {...props} />}
      />
      <Route
        path="/our-beneficiaries"
        exact
        render={props => <Beneficiaries {...props} />}
      />
      <Route
        path="/our-financial-report"
        exact
        render={props => <FinancialReport {...props} />}
      />
      <Route
        path="/our-story"
        exact
        render={props => <OurStory {...props} />}
      />
      <Route
        path="/blog"
        exact
        render={props => <Blog {...props} />}
      />
      <Route
        path="/causes"
        exact
        render={props => <Causes {...props} />}
      />
      <Route
        path="/gallery"
        exact
        render={props => <Gallery {...props} />}
      />
      <Route
        path="/donate"
        exact
        render={props => <Donate {...props} />}
      />
      <Route
        path="/contact"
        exact
        render={props => <Contact {...props} />}
      />
      <Route
        path="/secretary"
        exact
        render={props => <Secretary {...props} />}
      />
      <Route
        path="/executive-director"
        exact
        render={props => <Exdirector {...props} />}
      />
      <Route
        path="/president"
        exact
        render={props => <President {...props} />}
      />
      <Route
        path="/our-patriach"
        exact
        render={props => <Patriach {...props} />}
      />
      <Route
        path="/admin-officer"
        exact
        render={props => <Admin {...props} />}
      />
      <Route
        path="/director"
        exact
        render={props => <Director {...props} />}
      />
      <Route path="/login-page" exact render={props => <Login {...props} />} />
      <Route
        path="/profile-page"
        exact
        render={props => <Profile {...props} />}
      />
      <Route
        path="/register-page"
        exact
        render={props => <Register {...props} />}
      />
      <Redirect to="/" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
