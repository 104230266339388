import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";

// reactstrap components
import {
    Badge,
    Button,
    Card,
    CardBody,
    Container,
    Row,
    Col
  } from "reactstrap";
  
  // core components
  import DemoNavbar from "components/Navbars/DemoNavbar.js";
  import SimpleFooter from "components/Footers/SimpleFooter.js";
  import StoryHeader from "components/Headers/StoryHeader.js";
  import "tachyons";
  
  
  class OurStory extends React.Component {
    state = {};
    componentDidMount() {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.main.scrollTop = 0;
    }
    render() {
      return (
        <>
          <DemoNavbar />
          <main ref="main">
            <StoryHeader />
      
            <section className="section section-lg">
              <Container className="pb-300">
                <Row className="row-grid align-items-center">
                  <Col className="order-md-2" md="6">
                    <img
                      alt="..."
                      className="img-fluid floating"
                      src={require("assets/img/emma-and-grace-1.jpg")}
                    /><br /><br />
                    <img
                      alt="..."
                      className="img-fluid floating"
                      src={require("assets/img/emma-and-grace-2.jpg")}
                      />
                  </Col>
                  <Col className="order-md-1" md="6">
                    <div className="pr-md-5">
                     
                      <h3>Our Birth</h3>
                      <p>
                      Evangelist Iwuchukwu Ezenwafor was born on December 23, 
                      1965 in Port Harcourt and hails from Uga in Aguata LGA of Anambra State. 
                      He is the first son among the seven siblings of his parents. 
                      His early childhood upbringing was adversely affected by the civil war 
                      (1967-1970) as the family was devastatingly relocated to the homeland.
                      </p>
                      
                      <p>
                      He was raised from a humble background, as the parents struggled to find their 
                      footing after losing all their livelihood during the war. His lofty ambitious 
                      disposition played out when he left his high school programme to seek admission 
                      overseas. He believed studying in a world class university will avail him the best 
                      opportunity to test his extra-ordinary brilliance and thereby explore his academic prowess. 
                      He jealously nursed the ambition to post an unusual academic performance record that will 
                      automatically make him a player at the global stage with a particular interest in becoming a 
                      UN Secretary General. He got the admission but his dream was cut short by his parents’ 
                      inability to raise the necessary funds. In his characteristic attitude of facing challenges 
                      headlong, he secretly veered into business to make money to help himself.
                      </p>
                      
                      <p>
                      A resilient, articulate and astute manager of men and resources who virtually
                       started with nothing, he is today the President/CEO of <a href="http://www.iwundgroup.ng/" target="_blank"  rel="noopener noreferrer">IWUND GROUP</a> -comprising
                        of three thriving companies that are active players in the Nigerian building
                         industry: marketing, construction, and property/real estate. 
                      </p>

                      <p>
                      He does not want to leave the world the way he met it. By the reason of the divine 
                      grace and calling upon his life, he founded a nondenominational ministry – Universal 
                      Prayer Fellowship, a special purpose platform for demonstrating the principles and power 
                      of the KINGDOM. In the same spirit, his bowel moved in the direction of the brilliant and 
                      gifted orphans and indigent scholars. As a victim of aborted academic career/dream, he understands 
                      what it means to watch a life ambition elude one hence the FOUNDATION.
                      </p>
                    </div>
                  </Col>
                </Row>
              </Container>
            </section>
  
  
           
          
           
  
            <section className="section section-lg pt-lg-100 section-contact-us">
            <Container>
              <Row className="justify-content-center mt--300">
                <Col lg="8">
                  <Card className="bg-gradient-secondary shadow">
                    <CardBody className="p-lg-5">
                      <h4 className="mb-1">Want to work with us?</h4>
                      <p className="mt-0">
                        Your project is very important to us.
                      </p><br />
                      <p>Send us a message today, we look forward to hearing from you soon!</p>
                      <div>
                        <Button
                          block
                          className="btn-round"
                          color="default"
                          size="lg"
                          type="button"
                          target="_blank"
                          href="mailto:info@emmaandgracefoundation.org"
                        >
                          Send Message
                        </Button>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
          </main>
          <SimpleFooter />
        </>
      );
    }
  }
  
  export default OurStory;