import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";
import ContactHeader from "components/Headers/ContactHeader.js";
import "tachyons";


class Contact extends React.Component {
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <DemoNavbar />
        <main ref="main">
          <ContactHeader />
    
          <section className="section section-lg">
            <Container className="pb-300">
              <Row className="row-grid align-items-center">
                <Col className="order-md-1" >
                  <div className="pr-md-5 tc">
                   
                    <h3>Our Address</h3><br />
                    <h5 className="font-weight-light">
                        Plot 1552, Suite 10, Asokoro Mall, T.Y Danjuma Street, Asokoro District, Abuja, Nigeria.
                    </h5>
                    <br /><br />

                    <h3>Our Phone</h3><br />
                    <h5 className="font-weight-light">
                      (+234)08085162525
                    </h5>

                    <br /><br />

                    <h3>Our Email</h3><br />
                    <h5 className="font-weight-light">
                      info@emmaandgracefoundation.org
                    </h5>
                    

                  </div>
                </Col>
              </Row>
            </Container>
          </section>


         
        
         

          <section className="section section-lg pt-lg-100 section-contact-us">
            <Container>
              <Row className="justify-content-center mt--300">
                <Col lg="8">
                  <Card className="bg-gradient-secondary shadow">
                    <CardBody className="p-lg-5">
                      <h4 className="mb-1">Want to work with us?</h4>
                      <p className="mt-0">
                        Your project is very important to us.
                      </p><br />
                      <p>Send us a message today, we look forward to hearing from you soon!</p>
                      <div>
                        <Button
                          block
                          className="btn-round"
                          color="default"
                          size="lg"
                          type="button"
                          target="_blank"
                          href="mailto:info@emmaandgracefoundation.org"
                        >
                          Send Message
                        </Button>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        <SimpleFooter />
      </>
    );
  }
}

export default Contact;
