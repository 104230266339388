import React from "react";

// reactstrap components
import { Button, Card, Container, Row, Col } from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";

class President extends React.Component {
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <DemoNavbar />
        <main className="profile-page" ref="main">
          <section className="section-profile-cover section-shaped my-0">
            {/* Circles background */}
            <div className="shape shape-style-1 shape-default alpha-4">
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>
          <section className="section">
            <Container>
              <Card className="card-profile shadow mt--300">
                <div className="px-4">
                  <Row className="justify-content-center">
                    <Col className="order-lg-2" lg="3">
                      <div className="card-profile-image">
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          <img
                            alt="..."
                            className="rounded-circle"
                            src={require("assets/img/chairman.jpg")}
                          />
                        </a>
                      </div>
                    </Col>
                    
                  </Row>
                  <br /><br /><br /><br />
                  <div className="text-center mt-5">
                    <h3>
                      Evang. Iwuchukwu Ezenwafor
                    </h3>
                    <div className="h6 font-weight-300">
                      <i className="ni location_pin mr-2" />
                      Abuja, Nigeria
                    </div>
                    <div className="h6 mt-4">
                      <i className="ni business_briefcase-24 mr-2" />
                      President / Chairman Board of Trustees
                    </div>
                    <div>
                      <i className="ni education_hat mr-2" />
                     
                    </div>
                  </div>
                  <div className="mt-5 py-5 border-top text-center">
                    <Row className="justify-content-center">
                      <Col lg="9">
                        <p>
                        Our President, Evang. Iwuchukwu Ezenwafor was raised
                         from a very humble background as the parents struggled to 
                         find their footing after losing all their livelihood to 
                         the Nigeria Civil war.
                         However, his lofty ambitious disposition laced with an 
                         obsessed passion of playing at the global stage inspired 
                         him to seek admission to study and pursue his life ambition 
                         overseas. He got the admission but the dream was eventually 
                         cut short by obvious financial constrain.
                        </p>

                        <p>
                        In his characteristic attitude 
                        of facing challenges headlong, he secretly 
                        veered into business to make money to help 
                        himself. A resilient, articulate and astute manager of 
                        men and resources who virtually started with nothing is today 
                        the President/CEO of <a href="http://www.iwundgroup.ng/" target="_blank"  rel="noopener noreferrer">IWUND GROUP</a> 
                        – comprising of three thriving companies in Agro-business, 
                        Construction, Property/real estate. He is a pioneer Director
                         in Wichtech Industries Ltd and several other companies.
                        </p>

                        <p>
                        His vision of streamlined open market gospel/prayer meetings initiated first at the 
                        renowned commercial nerve centre, Onitsha  ushered him into  itenerary evangelistic 
                        career. He has over the years authored and published several soul-winning/revival 
                        materials distributed free-of-charge in the course of the routine Gospel Campaigns 
                        spanning a period of more than two decades now.
                        </p>

                        <p>
                        He understands what it means to watch a life ambition elude one hence he founded 
                        EMMA & GRACE EDUCATION FOUNDATION. The Foundation supports widows and grants scholarships 
                        to gifted orphans and indigent youths.
                        </p>
                        
                        <p>
                        He does not want to leave the world the way he met it. By the special grace and calling upon
                         his life, he founded a non-denominational ministry: UNIVERSAL PRAYER FELLOWSHIP, a special 
                         purpose platform for demonstrating the principles and power of the KINGDOM with enviable record 
                         of tangible testimonies and diverse achievements.
                        </p>
                        <p>
                        For the love of the fatherland, he founded the IGBO REINVENTION MOVEMENT (IRM) to chart the course
                         of TRUE recovery, reconciliation and restoration of hitherto vanquished and hurting people. 
                         The MOVEMENT is presently spearheading a massive Farming/Agro business revolution in Igbo Land 
                         synergizing with Israeli experts.
                        </p>
                        <p>
                        He is liberally gifted with incredible but edifying utterance of wisdom, word of knowledge, and unraveling of mysteries
                        Above all, he is summarily called to be a catalyst to the climactic revival that must precede the imminent rapture of the Church.

                        He is married to an anointed vessel of God – Didi Blessing and they are blessed with children of signs and wonders.   
                        </p>
                        <br />
                        <div className="mt-3">
                        <Button
                          className="btn-icon-only rounded-circle"
                          color="warning"
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          <i className="fa fa-instagram" />
                        </Button>
                        <Button
                          className="btn-icon-only rounded-circle ml-1"
                          color="warning"
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          <i className="fa fa-facebook" />
                        </Button>
                        <Button
                          className="btn-icon-only rounded-circle ml-1"
                          color="warning"
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          <i className="fa fa-linkedin" />
                        </Button>
                      </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Card>
            </Container>
          </section>
        </main>
        <SimpleFooter />
      </>
    );
  }
}

export default President;
