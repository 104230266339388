import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
import PDFViewer from "pdf-viewer-reactjs";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";
import FinancialHeader from "components/Headers/FinancialHeader.js";
import Report from "./Report.js";
import "tachyons";


class FinancialReport extends React.Component {
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <DemoNavbar />
        <main ref="main">
          <FinancialHeader />
    
          <section className="section section-lg bg-success">
            <Container className="pb-300">
            <h3>Financial Report</h3><br /><br />
              <Row className="justify-content-center">
                
                <Col lg="13">

                   <Report />
                </Col>

              </Row>
            </Container>
          </section>

         

          <section className="section section-lg pt-lg-100 section-contact-us">
            <Container>
              <Row className="justify-content-center mt--300">
                <Col lg="8">
                  <Card className="bg-gradient-secondary shadow">
                    <CardBody className="p-lg-5">
                      <h4 className="mb-1">Want to work with us?</h4>
                      <p className="mt-0">
                        Your project is very important to us.
                      </p><br />
                      <p>Send us a message today, we look forward to hearing from you soon!</p>
                      <div>
                        <Button
                          block
                          className="btn-round"
                          color="default"
                          size="lg"
                          type="button"
                          target="_blank"
                          href="mailto:info@emmaandgracefoundation.org"
                        >
                          Send Message
                        </Button>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        <SimpleFooter />
      </>
    );
  }
}

export default FinancialReport;
