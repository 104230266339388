import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";

// reactstrap components
import {
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Button,
  Card,
  CardBody,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";
import BlogHeader from "components/Headers/BlogHeader.js";
import "tachyons";


class Blog extends React.Component {
  state = {
    iconTabs: 1,
    plainTabs: 1
  };
  toggleNavs = (e, state, index) => {
    e.preventDefault();
    this.setState({
      [state]: index
    });
  };
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <DemoNavbar />
        <main ref="main">
          <BlogHeader />
    
          <section className="section section-lg">
            <Container className="pb-300">
              <h3>Our Blog</h3>
              <Row className="justify-content-center">
                <Col className="mt-5 mt-lg-0" lg="12">
            {/* Menu */}
            <div className="nav-wrapper">
              <Nav
                className="nav-fill flex-column flex-md-row"
                id="tabs-icons-text"
                pills
                role="tablist"
              >
                <NavItem>
                  <NavLink
                    aria-selected={this.state.plainTabs === 1}
                    className={classnames("mb-sm-3 mb-md-0", {
                      active: this.state.plainTabs === 1
                    })}
                    onClick={e => this.toggleNavs(e, "plainTabs", 1)}
                    href="#pablo"
                    role="tab"
                  >
                   New Executive Director resumes in our Abuja office
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    aria-selected={this.state.plainTabs === 2}
                    className={classnames("mb-sm-3 mb-md-0", {
                      active: this.state.plainTabs === 2
                    })}
                    onClick={e => this.toggleNavs(e, "plainTabs", 2)}
                    href="#pablo"
                    role="tab"
                  >
                   Message from the Chairman
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    aria-selected={this.state.plainTabs === 3}
                    className={classnames("mb-sm-3 mb-md-0", {
                      active: this.state.plainTabs === 3
                    })}
                    onClick={e => this.toggleNavs(e, "plainTabs", 3)}
                    href="#pablo"
                    role="tab"
                  >
                  Six Year Anniversary Celebration
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
            <Card className="shadow">
              <CardBody>
                <TabContent activeTab={"plainTabs" + this.state.plainTabs}>
                  <TabPane tabId="plainTabs1">
                  <div className="tc"><img
                    alt="..."
                    className="img-fluid"
                    src={require("assets/img/exdirector.jpg")}
                  /><br /><br />
                  <h5>New Executive Director resumes in our Abuja office</h5>
                  </div><br />
                  
                    <p className="description">
                    Emma and Grace Foundation has hired the services of a 
                    seasoned expert on NGO management and Sustainable Development, 
                    Rev Jude E. Mbonu. He resumed duties in August, 2019 as the 
                    Executive Director of the foundation with his office based in 
                    the Abuja corporate headquarters of the foundation. He has worked 
                    with Embassy of Japan in Nigeria, office of MDGs in State house, 
                    Abuja. He has a special consultative status with United Nations 
                    on the implementation of SDGs…Rev Mbonu will oversee the entire 
                    programs of Emma and Grace Foundation both in Abuja and Anambra State.
                    </p><br />
                    <small>
                    November 19, 2019
                    </small>
                    
                  </TabPane>
                  <TabPane tabId="plainTabs2">
                  <div className="tc"><img
                    alt="..."
                    className="img-fluid"
                    src={require("assets/img/chairman.jpg")}
                  /><br /><br />
                  <h5>Message from the Chairman</h5>
                  </div><br />
                  
                    <p className="description">
                    The true picture of the twenty-first century Nigeria and indeed Africa is a 
                    sheer nightmare when juxtaposed with all other continents especially the first
                     world axis. Sensitive people from around the globe are increasingly baffled, 
                     dazed and alarmed with the realiMessage from the Chairmanty of the unfolding 
                     complexity of the contemporary African challenges. There seems to be no reasonable 
                     solution readily at sight, not to talk of a ray of hope or any sign of light at 
                     the end of the tunnel.
                    </p>
                    <p className="description">
                    The entire system is virtually broken down – political leadership, economy, health, 
                    education, culture and even the social fabric are incredibly in shambles. In a nutshell,
                     what we are actually experiencing in all spheres is better described as not 
                     only chaotic but an inexplicable cataclysmic imbroglio!
                    </p>
                    <p className="description">
                    At this point in the checkered history of this continent, a voice of reason must be raised 
                    to demystify the prevailing tragedy, pin the issues down, hit the nail at the head 
                    and forever rest this case of African proverbial poverty. Can’t we for the first
                     time muster enough courage and garner the enabling gut to grapple with the 
                     root-cause of our backwardness and depravity. How long shall we think that 
                     Africa shall be fixed by a father Christmas from the blues?
                    </p>
                    <p className="description">
                    It is our collective responsibility to arise for total emancipation of Africa. 
                    We should halt the trend of exodus for golden fleece. The young generation of
                     African professionals in the Diaspora should rethink. After the spell of 
                     acquiring knowledge, training, experience and possibly financial empowerment, 
                     it is time to return back to duty.
                    </p>
                    <p className="description">
                    Take your destiny in your hand, and contribute your own quota by helping to 
                    replicate what in the first place made your host clime attractive as part 
                    of the first world. Let us pool our human resources together and tap into the
                     available diverse resources. If we can only maximize the exploration of our 
                     endowed rich land resource through modern Agro-business techniques, we will 
                     certainly catalyze the long awaited African Industrial revolution.
                    </p>
                    <img
                    alt="..."
                    className="img-fluid"
                    src={require("assets/img/signature.png")}
                  /><br /><br /><br />
                    <small>
                    February 21, 2018
                    </small>
                  </TabPane>
                  <TabPane tabId="plainTabs3">
                  <div className="tc"><img
                    alt="..."
                    className="img-fluid"
                    src={require("assets/img/anniversary.png")}
                  /><br /><br />
                  <h5>Emma and Grace Foundation Celebrates Six Years Anniversary</h5><p>January 2016</p>
                  </div><br />
                  
                    <p className="description">
                    Emma & Grace Education was conceived six years ago by Evangelist Iwuchukwu 
                    Ezenwafor was named after his parents, Emma and Grace, in their loving memories.
                    </p>
                    <p className="description">
                      For the first five years of its existence (2010-2015), the foundation had silently 
                      brought succor to the community of four villages in Uga. The journey actually started 
                      12 years ago with the donation of 3 bags of rice to the widows in a prayer meeting. 
                      This grew to 8 bags of rice in the following year. The benevolence upgraded and operated 
                      as a full-fledged charity organization taking care of widows and sponsoring indigent 
                      scholars of Uga, Aguata Local Government Area (LGA), Anambra State.
                      On January 1, 2016, which marks its 6th anniversary, the foundation, for the first time, 
                      made a public presentation of its affairs to members of the Uga community.
                     </p>
                     <p className="description">
                     Ms. Chisom Loveth Okoroji, one of the pioneer beneficiaries of the tertiary education scholarship
                      program of Emma and Grace Education Foundation and now a staff of Procter and Gamble Nigeria, 
                      (Lagos headquarters) attended. In her thank you speech, on behalf of all the other beneficiaries, 
                      Ms. Okoroji said, “The Scripture stated that the religion that is pure and undefiled before God, 
                      the Father, is this: to visit orphans and widows in the affliction.
                     </p>
                    <br />
                    <small>
                    February 20, 2018
                    </small>
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Col>
              </Row>
            </Container>
          </section>


         
        
         

          <section className="section section-lg pt-lg-100 section-contact-us">
            <Container>
              <Row className="justify-content-center mt--300">
                <Col lg="8">
                  <Card className="bg-gradient-secondary shadow">
                    <CardBody className="p-lg-5">
                      <h4 className="mb-1">Want to work with us?</h4>
                      <p className="mt-0">
                        Your project is very important to us.
                      </p><br />
                      <p>Send us a message today, we look forward to hearing from you soon!</p>
                      <div>
                        <Button
                          block
                          className="btn-round"
                          color="default"
                          size="lg"
                          type="button"
                          target="_blank"
                          href="mailto:info@emmaandgracefoundation.org"
                        >
                          Send Message
                        </Button>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        <SimpleFooter />
      </>
    );
  }
}

export default Blog;
