import React from "react";

// reactstrap components
import { Button, Card, Container, Row, Col } from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";

class Director extends React.Component {
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <DemoNavbar />
        <main className="profile-page" ref="main">
          <section className="section-profile-cover section-shaped my-0">
            {/* Circles background */}
            <div className="shape shape-style-1 shape-default alpha-4">
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>
          <section className="section">
            <Container>
              <Card className="card-profile shadow mt--300">
                <div className="px-4">
                  <Row className="justify-content-center">
                    <Col className="order-lg-2" lg="3">
                      <div className="card-profile-image">
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          <img
                            alt="..."
                            className="rounded-circle"
                            src={require("assets/img/director.jpeg")}
                          />
                        </a>
                      </div>
                    </Col>
                    
                  </Row>
                  <br /><br /><br /><br />
                  <div className="text-center mt-5">
                    <h3>
                      Engr. Chudalu Ezenwafor
                    </h3>
                    <div className="h6 font-weight-300">
                      <i className="ni location_pin mr-2" />
                      Abuja, Nigeria
                    </div>
                    <div className="h6 mt-4">
                      <i className="ni business_briefcase-24 mr-2" />
                      Director, Programs/Partnership & Volunteer Officers
                    </div>
                    <div>
                      <i className="ni education_hat mr-2" />
                        <p>MSc. Artificial Intelligence, University of Edinburgh</p>
                        <p>BSc. Computer Engineering, Ghana Technology University College</p>
                    </div>
                  </div>
                  <div className="mt-5 py-5 border-top text-center">
                    <Row className="justify-content-center">
                      <Col lg="9">
                        <p>
                        A Young and Energetic man with a vision to impact the world. He is a 
                      Computer Engineer and holds a MSc. degree. He contributes greatly by reaching out
                      to people by means of charity.
                        </p>

                        <p>
                            He performed a great service to this establishment by developing our website with no fee or charges. 
                            He continues to give charitably to the needy. We thank God for him!
                        </p>

                        <br />
                        <div className="mt-3">
                        <Button
                          className="btn-icon-only rounded-circle"
                          color="info"
                          href="https://www.linkedin.com/in/chudalu-ezenwafor-518aa677/"
                          target="_blank"
                        >
                          <i className="fa fa-linkedin" />
                        </Button>
                        
                      </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Card>
            </Container>
          </section>
        </main>
        <SimpleFooter />
      </>
    );
  }
}

export default Director;
