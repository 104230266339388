import React from "react";

// reactstrap components
import { Button, Card, Container, Row, Col } from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";

class Secretary extends React.Component {
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <DemoNavbar />
        <main className="profile-page" ref="main">
          <section className="section-profile-cover section-shaped my-0">
            {/* Circles background */}
            <div className="shape shape-style-1 shape-default alpha-4">
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>
          <section className="section">
            <Container>
              <Card className="card-profile shadow mt--300">
                <div className="px-4">
                  <Row className="justify-content-center">
                    <Col className="order-lg-2" lg="3">
                      <div className="card-profile-image">
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          <img
                            alt="..."
                            className="rounded-circle"
                            src={require("assets/img/chidera.jpg")}
                          />
                        </a>
                      </div>
                    </Col>
                    
                  </Row>
                  <br /><br /><br /><br />
                  <div className="text-center mt-5">
                    <h3>
                      Barr. Chidera Nkonyel Ezenwafor
                    </h3>
                    <div className="h6 font-weight-300">
                      <i className="ni location_pin mr-2" />
                      Abuja, Nigeria
                    </div>
                    <div className="h6 mt-4">
                      <i className="ni business_briefcase-24 mr-2" />
                      Secretary
                    </div>
                    <div>
                      <i className="ni education_hat mr-2" />
                      <p>MSc. International Relations, Baze University</p>
                        <p>LL. B. Law, Baze University</p>
                    </div>
                  </div>
                  <div className="mt-5 py-5 border-top text-center">
                    <Row className="justify-content-center">
                      <Col lg="9">
                        <p >
                                A powerful young woman that has spear-headed the needful impact of 
                                positivity in the youth, especially within women. she is a Lawyer that has been accredited
                                by the Nigerian Bar Association. She also runs her personal foundation and charity events. 
                                She is a certified advocate on issues bothering on mental health and it’s awareness, women inclusiveness.
                                She’s currently running her Masters in the Field of International Relations and Diplomacy. 
                                Over time, she has gathered training and experience from non-profit organizations such as the Friedrich-Ebert Stiftung. 
                                This is a German Foundation focused on driving political Education and democracy. She has volunteered and served with civil society groups. 
                                She is an ardent promoter of Youth Empowerment and Entrepreneurship.
                            </p>

                        <br />
                        <div className="mt-3">
                        <Button
                          className="btn-icon-only rounded-circle"
                          color="success"
                          href="https://www.instagram.com/cherish_mica/"
                          target="_blank"
                        >
                          <i className="fa fa-instagram" />
                        </Button>
                        <Button
                          className="btn-icon-only rounded-circle ml-1"
                          color="success"
                          target="_blank"
                          href="https://www.facebook.com/cherishezenwafor"
                        >
                          <i className="fa fa-facebook" />
                        </Button>
                        
                      </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Card>
            </Container>
          </section>
        </main>
        <SimpleFooter />
      </>
    );
  }
}

export default Secretary;
