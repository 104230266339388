import React from "react";
import { Link } from "react-router-dom";
// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from "headroom.js";
// reactstrap components
import {
  Button,
  UncontrolledCollapse,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col
} from "reactstrap";

class DemoNavbar extends React.Component {
  componentDidMount() {
    let headroom = new Headroom(document.getElementById("navbar-main"));
    // initialise
    headroom.init();
  }
  state = {
    collapseClasses: "",
    collapseOpen: false
  };

  onExiting = () => {
    this.setState({
      collapseClasses: "collapsing-out"
    });
  };

  onExited = () => {
    this.setState({
      collapseClasses: ""
    });
  };

  render() {
    return (
      <>
        <header className="header-global">
          <Navbar
            className="navbar-main navbar-transparent navbar-light headroom"
            expand="lg"
            id="navbar-main"
          >
            <Container>
              <NavbarBrand className="mr-lg-5" to="/" tag={Link}>
                <img
                  alt="..."
                  src={require("assets/img/eandg.png")}
                  style={{ width: "200px", height:'50px' }}
                />
              </NavbarBrand>
              <button className="navbar-toggler" id="navbar_global">
                <span className="navbar-toggler-icon" />
              </button>
              <UncontrolledCollapse
                toggler="#navbar_global"
                navbar
                className={this.state.collapseClasses}
                onExiting={this.onExiting}
                onExited={this.onExited}
              >
                <div className="navbar-collapse-header">
                  <Row>
                    <Col className="collapse-brand" xs="6">
                      <Link to="/">
                        <img
                          alt="..."
                          src={require("assets/img/blueeandg.png")}
                          style={{ width: "200px", height:'40px' }}
                        />
                      </Link>
                    </Col>
                    <Col className="collapse-close" xs="6">
                      <button className="navbar-toggler" id="navbar_global">
                        <span />
                        <span />
                      </button>
                    </Col>
                  </Row>
                </div>
                
                <Nav className="navbar-nav-hover align-items-lg-center ml-lg-auto" navbar>
                
                <UncontrolledDropdown nav>
                    <DropdownToggle nav>
                      <i className="ni ni-world d-lg-none mr-1" />
                      <span className="nav-link-inner--text">About Us</span>
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem to="/our-patriach" tag={Link}>
                        Our Patriach
                      </DropdownItem>
                      <DropdownItem to="/our-story" tag={Link}>
                        Our Story
                      </DropdownItem>
                      <DropdownItem to="/our-beneficiaries" tag={Link}>
                        Our Beneficiaries
                      </DropdownItem>
                      <DropdownItem to="/our-financial-report" tag={Link}>
                        Our Financial Report
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>

                  <UncontrolledDropdown nav>
                    <DropdownToggle nav>
                      <i className="ni ni-briefcase-24 d-lg-none mr-1" />
                      <span className="nav-link-inner--text">The Team</span>
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem to="/president" tag={Link}>
                        The President
                      </DropdownItem>
                      <DropdownItem to="/executive-director" tag={Link}>
                        The Executive Director
                      </DropdownItem>
                      <DropdownItem to="/director" tag={Link}>
                        The Director
                      </DropdownItem>
                      <DropdownItem to="/admin-officer" tag={Link}>
                        The Admin /Communications officer
                      </DropdownItem>
                      <DropdownItem to="/secretary" tag={Link}>
                        The Secretary
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                
                  <NavItem>
                    <NavLink
                      className="nav-link-icon"
                      href="/causes"
                    >
                     <i className="ni ni-diamond d-lg-none mr-1" />
                      <span className="nav-link-inner--text">Our Causes</span>
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      className="nav-link-icon"
                      href="/contact"
                    >
                     <i className="ni ni-collection d-lg-none mr-1" />
                      <span className="nav-link-inner--text">Contact</span>
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      className="nav-link-icon"
                      href="/blog"
                    >
                     <i className="ni ni-album-2 d-lg-none mr-1" />
                      <span className="nav-link-inner--text">Blog</span>
                    </NavLink>
                  </NavItem>
                
                  <NavItem style={{paddingRight:"50px"}}>
                    <NavLink
                      className="nav-link-icon"
                      href="/gallery"
                    >
                     <i className="ni ni-camera-compact d-lg-none mr-1" />
                      <span className="nav-link-inner--text">Gallery</span>
                    </NavLink>
                  </NavItem>
                  
                  <NavItem style={{paddingBottom:"20px"}}>
                  <Button
                            className="mt-4"
                            color="success"
                            href="/social-platform"
                          >
                            Login
                      </Button>
                  </NavItem>
                </Nav>
              </UncontrolledCollapse>
            </Container>
          </Navbar>
        </header>
      </>
    );
  }
}

export default DemoNavbar;
