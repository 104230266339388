import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardImg,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";
import HomeHeader from "components/Headers/HomeHeader.js";
import Scroll from "components/utility/Scroll.js";
import "tachyons";


class Landing extends React.Component {
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <DemoNavbar />
        <main ref="main">
          <HomeHeader />
          <section className="section section-lg pt-lg-0 mt--200">
            <Container>
              <Row className="justify-content-center">
                <Col lg="12">
                  <Row className="row-grid">
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5">
                          <div className="icon icon-shape icon-shape-primary rounded-circle mb-4">
                            <i className="ni ni-check-bold" />
                          </div>
                          <h6 className="text-primary text-uppercase">
                            Become a Volunteer
                          </h6>
                          <p className="description mt-3">
                          Join hands with us for a better life and beautiful future
                          </p>
                          <div>
                            <Badge color="primary" pill className="mr-1">
                              service
                            </Badge>
                            <Badge color="primary" pill className="mr-1">
                              community
                            </Badge>
                            <Badge color="primary" pill className="mr-1">
                              selflessness
                            </Badge>
                          </div>
                          <Button
                            className="mt-4"
                            color="primary"
                            href="/social-platform"
                          >
                            Register
                          </Button>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5">
                          <div className="icon icon-shape icon-shape-success rounded-circle mb-4">
                            <i className="ni ni-credit-card" />
                          </div>
                          <h6 className="text-success text-uppercase">
                            Make a Donation
                          </h6>
                          <p className="description mt-3">
                          Give little, save much. Partner with us, and support a cause. 
                          </p>
                          <div>
                            <Badge color="success" pill className="mr-1">
                              aid
                            </Badge>
                            <Badge color="success" pill className="mr-1">
                              charity
                            </Badge>
                            <Badge color="success" pill className="mr-1">
                              help
                            </Badge>
                          </div>
                          <Button
                            className="mt-4"
                            color="success"
                            href="/donate"
                          >
                            Donate Now
                          </Button>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5">
                          <div className="icon icon-shape icon-shape-warning rounded-circle mb-4">
                            <i className="ni ni-world" />
                          </div>
                          <h6 className="text-warning text-uppercase">
                            Give a Scholarship
                          </h6>
                          <p className="description mt-3">
                          You can give a donation to Emma & Grace Scholarship to help pay for college
                          </p>
                          <div>
                            <Badge color="warning" pill className="mr-1">
                              Sponsorship
                            </Badge>
                            <Badge color="warning" pill className="mr-1">
                              Academic training
                            </Badge>
                            <Badge color="warning" pill className="mr-1">
                              future leaders
                            </Badge>
                          </div>
                          <Button
                            className="mt-4"
                            color="warning"
                            href="/donate"
                          >
                            Give Scholarship
                          </Button>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="section section-lg">
            <Container>
              <Row className="row-grid align-items-center">
                <Col className="order-md-2" md="6">
                  <img
                    alt="..."
                    className="img-fluid floating"
                    src={require("assets/img/emma-and-grace-1.jpg")}
                  />
                </Col>
                <Col className="order-md-1" md="6">
                  <div className="pr-md-5">
                   
                    <h3>Who We Are</h3>
                    <p>
                      We are a selfless and dedicated institution that have recieved a divine mandate
                      from God to provide aid, assistance and support to the less priviledged. Since the inception
                      of our cause to aid and support, we have made impact in areas such as academics, infrastructural
                      developments, charitable activities and so on. We are unrelenting in providing our aid because we
                      understand the hardship that anyone can face. We are blessed to be able to impact lives prositively, 
                      and we do not take it for granted. The sectors of our services are:
                    </p>
                    <ul className="list-unstyled mt-5">
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="success"
                            >
                              <i className="ni ni-ruler-pencil" />
                            </Badge>
                          </div>
                          <div>
                            <h6 className="mb-0">
                              Academic Scholarships - tertiary and non-tertiary education
                            </h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="success"
                            >
                              <i className="ni ni-settings" />
                            </Badge>
                          </div>
                          <div>
                            <h6 className="mb-0">School Construction /Development programme</h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="success"
                            >
                              <i className="ni ni-satisfied" />
                            </Badge>
                          </div>
                          <div>
                            <h6 className="mb-0">
                               Widow care 
                            </h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="success"
                            >
                              <i className="ni ni-building" />
                            </Badge>
                          </div>
                          <div>
                            <h6 className="mb-0">
                                Community Development Projects
                            </h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="success"
                            >
                              <i className="ni ni-atom" />
                            </Badge>
                          </div>
                          <div>
                            <h6 className="mb-0">
                                Skills Acquisition Center
                            </h6>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>

          <section className="section pb-0 bg-gradient-warning">
            <Container>
              <Row className="row-grid align-items-center">
                <Col className="order-lg-1" lg="6">
                <Card className="shadow shadow-lg--hover mt-5">
                    <CardBody>
                      <div className="d-flex px-3">
                        <div>
                          <div className="icon icon-shape bg-gradient-info rounded-circle text-white">
                            <i className="ni ni-chart-bar-32" />
                          </div>
                        </div>
                        <div className="pl-4">
                          <h5 className="title text-info">
                            Our Mission
                          </h5>
                          <p>
                          A deliberate service to God & Humanity, 
                          creating an enduring legacy for raising a generation of academically excellent
                           & selfless leaders that would help make the world a better place for all


                          </p>
                         
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col className="order-lg-1" lg="6">
                 
                  <Card className="shadow shadow-lg--hover mt-5">
                    <CardBody>
                      <div className="d-flex px-3">
                        <div>
                          <div className="icon icon-shape bg-gradient-success rounded-circle text-white">
                            <i className="ni ni-camera-compact" />
                          </div>
                        </div>
                        <div className="pl-4">
                          <h5 className="title text-success">
                            Our Vision
                          </h5>
                          <p>
                          Sacrificial investment of resources in human capital development
                           among those who would have hitherto for no fault of theirs been denied 
                           of the life opportunity.


                          </p>
                          <a
                            className="text-success"
                            href="/causes"
                          >
                            Learn more
                          </a>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                  <Card className="shadow shadow-lg--hover mt-5">
                    <CardBody>
                      <div className="d-flex px-3">
                        <div>
                          <div className="icon icon-shape bg-gradient-warning rounded-circle text-white">
                            <i className="ni ni-active-40" />
                          </div>
                        </div>
                        <div className="pl-4">
                          <h5 className="title text-warning">
                            Our History
                          </h5>
                          <p>
                          Our journey started on 10th day of April, 2010 and to the Glory of God, 
                          we haven’t looked back. So far, our scholarship awardees & widows in our Care 
                          Group have annually grown in numbers


                          </p>
                          <a
                            className="text-warning"
                            href="/our-story"
                          >
                            Learn more
                          </a>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <br /> <br /><br /> <br />
            </Container>
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>

          <section className="section bg-secondary">
            <Container>
            <h3>Our Causes</h3><br />
              <Row className="row-grid align-items-center">
                <Col lg="4">
                  <Card className="bg-default shadow border-0">
                    <CardImg
                      alt="..."
                      src={require("assets/img/students.png")}
                      top
                    />
                    <blockquote className="card-blockquote">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="svg-bg"
                        preserveAspectRatio="none"
                        viewBox="0 0 583 95"
                      >
                        <polygon
                          className="fill-default"
                          points="0,52 583,95 0,95"
                        />
                        <polygon
                          className="fill-default"
                          opacity=".2"
                          points="0,42 583,95 683,0 0,95"
                        />
                      </svg>
                      <h4 className="display-5 font-weight-bold text-white">
                       Youth Scholarship - 2020/21 Academic Year Budget
                      </h4>
                      <p className="lead text-italic text-white">
                        
                      </p>
                      <Button
                            className="mt-4"
                            color="success"
                            href="/donate"
                          >
                            Donate
                      </Button>
                     
                      <Button
                            className="mt-4"
                            color="info"
                            href="/causes#youth-scholarships"
                          >
                            Learn more
                      </Button>
                     
                    </blockquote>
                    
                  </Card>
                  <br /> <br />
                </Col>
                <Col lg="4">
                  <Card className="bg-default shadow border-0">
                    <CardImg
                      alt="..."
                      src={require("assets/img/widows.png")}
                      top
                    />
                    <blockquote className="card-blockquote">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="svg-bg"
                        preserveAspectRatio="none"
                        viewBox="0 0 583 95"
                      >
                        <polygon
                          className="fill-default"
                          points="0,52 583,95 0,95"
                        />
                        <polygon
                          className="fill-default"
                          opacity=".2"
                          points="0,42 583,95 683,0 0,95"
                        />
                      </svg>
                      <h4 className="display-5 font-weight-bold text-white">
                        Widow Care Group - Welfare Budget
                      </h4>
                      <p className="lead text-italic text-white">
                       
                      </p>
                      <Button
                            className="mt-4"
                            color="success"
                            href="/donate"
                          >
                            Donate
                      </Button>
                     
                      <Button
                            className="mt-4"
                            color="info"
                            href="/causes#widow-care"
                          >
                            Learn more
                      </Button>
                    </blockquote>
                  </Card>
                  <br /> <br />
                </Col>
                <Col lg="4">
                  <Card className="bg-default shadow border-0">
                    <CardImg
                      alt="..."
                      src={require("assets/img/soa.jpg")}
                      top
                    />
                    <blockquote className="card-blockquote">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="svg-bg"
                        preserveAspectRatio="none"
                        viewBox="0 0 583 95"
                      >
                        <polygon
                          className="fill-default"
                          points="0,52 583,95 0,95"
                        />
                        <polygon
                          className="fill-default"
                          opacity=".2"
                          points="0,42 583,95 683,0 0,95"
                        />
                      </svg>
                      <h4 className="display-5 font-weight-bold text-white">
                        Seeds of Abraham Academy Project
                      </h4>
                      <Button
                            className="mt-4"
                            color="success"
                            href="/donate"
                          >
                            Donate
                      </Button>
                     
                      <Button
                            className="mt-4"
                            color="info"
                            href="/causes#academy"
                          >
                            Learn more
                      </Button>
                    </blockquote>
                  </Card>
                  <br /> <br />
                </Col>
                <Col lg="4">
                  <Card className="bg-default shadow border-0">
                    <CardImg
                      alt="..."
                      src={require("assets/img/skills.jpg")}
                      top
                    />
                    <blockquote className="card-blockquote">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="svg-bg"
                        preserveAspectRatio="none"
                        viewBox="0 0 583 95"
                      >
                        <polygon
                          className="fill-default"
                          points="0,52 583,95 0,95"
                        />
                        <polygon
                          className="fill-default"
                          opacity=".2"
                          points="0,42 583,95 683,0 0,95"
                        />
                      </svg>
                      <h4 className="display-5 font-weight-bold text-white">
                        Skills Acquisition Center in Umuoru - Uga, Anambra
                      </h4>
                      <Button
                            className="mt-4"
                            color="success"
                            href="/donate"
                          >
                            Donate
                      </Button>
                     
                      <Button
                            className="mt-4"
                            color="info"
                            href="/causes#skills-acquisition"
                          >
                            Learn more
                      </Button>
                    </blockquote>
                  </Card>
                  <br /> <br />
                </Col>
                <Col lg="4">
                  <Card className="bg-default shadow border-0">
                    <CardImg
                      alt="..."
                      src={require("assets/img/uga.jpg")}
                      top
                    />
                    <blockquote className="card-blockquote">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="svg-bg"
                        preserveAspectRatio="none"
                        viewBox="0 0 583 95"
                      >
                        <polygon
                          className="fill-default"
                          points="0,52 583,95 0,95"
                        />
                        <polygon
                          className="fill-default"
                          opacity=".2"
                          points="0,42 583,95 683,0 0,95"
                        />
                      </svg>
                      <h4 className="display-5 font-weight-bold text-white">
                        Development Project: Uga Unity Square
                      </h4>
                      <Button
                            className="mt-4"
                            color="success"
                            href="/donate"
                          >
                            Donate
                      </Button>
                     
                      <Button
                            className="mt-4"
                            color="info"
                            href="/causes#community-development"
                          >
                            Learn more
                      </Button>
                    </blockquote>
                  </Card>
                  <br /> <br />
                </Col>
              </Row>
            </Container>
          </section>

          <section className="section section-lg">
            <Container>
              <Row className="justify-content-center text-center mb-lg">
                <Col lg="8">
                  <h2 className="display-3">The amazing Team</h2>
                  <p className="lead text-muted">
                    Our amazing volunteers work hard all year round to make sure we
                    are impacting lives in the best possible way!
                  </p>
                </Col>
              </Row>
              <Row>
                <Col className="mb-5 mb-lg-0" lg="3" md="6">
                  <div className="px-4">
                    <img
                      alt="..."
                      className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                      src={require("assets/img/chairman.jpg")}
                      style={{ width: "200px" }}
                    />
                    <div className="pt-4 text-center">
                      <h5 className="title">
                        <a href="\president">
                        <span className="d-block mb-1">Evang. Iwuchukwu Ezenwafor</span>
                        <small className="h6 text-muted">President/ Chairman Board of Trustees</small></a>
                      </h5>
                      <div className="mt-3">
                        <Button
                          className="btn-icon-only rounded-circle"
                          color="warning"
                          href="/president"
                        >
                          <i className="fa fa-instagram" />
                        </Button>
                        <Button
                          className="btn-icon-only rounded-circle ml-1"
                          color="warning"
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          <i className="fa fa-facebook" />
                        </Button>
                        <Button
                          className="btn-icon-only rounded-circle ml-1"
                          color="warning"
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          <i className="fa fa-linkedin" />
                        </Button>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col className="mb-5 mb-lg-0" lg="3" md="6">
                  <div className="px-4">
                    <img
                      alt="..."
                      className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                      src={require("assets/img/exdirector.jpg")}
                      style={{ width: "200px" }}
                    />
                    <div className="pt-4 text-center">
                      <h5 className="title">
                      <a href="\executive-director">
                        <span className="d-block mb-1">Rev. Jude E. Mbonu</span>
                        <small className="h6 text-muted">
                          Executive Director
                        </small></a>
                      </h5>
                      <div className="mt-3">
                        <Button
                          className="btn-icon-only rounded-circle"
                          color="primary"
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          <i className="fa fa-instagram" />
                        </Button>
                        <Button
                          className="btn-icon-only rounded-circle ml-1"
                          color="primary"
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          <i className="fa fa-facebook" />
                        </Button>
                        <Button
                          className="btn-icon-only rounded-circle ml-1"
                          color="primary"
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          <i className="fa fa-linkedin" />
                        </Button>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col className="mb-5 mb-lg-0" lg="3" md="6">
                  <div className="px-4">
                    <img
                      alt="..."
                      className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                      src={require("assets/img/director.jpeg")}
                      style={{ width: "200px" }}
                    />
                    <div className="pt-4 text-center">
                      <h5 className="title">
                      <a href="\director">
                        <span className="d-block mb-1">Engr. Chudalu Ezenwafor</span>
                        <small className="h6 text-muted">Director, Programs/ Partnership & Volunteer Officers</small>
                      </a></h5>
                      <div className="mt-3">
                        <Button
                          className="btn-icon-only rounded-circle"
                          color="info"
                          href="https://www.linkedin.com/in/chudalu-ezenwafor-518aa677/"
                          target="_blank"
                        >
                          <i className="fa fa-linkedin" />
                        </Button>
                        
                      </div>
                    </div>
                  </div>
                </Col>
                <Col className="mb-5 mb-lg-0" lg="3" md="6">
                  <div className="px-4">
                    <img
                      alt="..."
                      className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                      src={require("assets/img/admin.jpg")}
                      style={{ width: "200px" }}
                    />
                    <div className="pt-4 text-center">
                      <h5 className="title">
                      <a href="\admin-officer">
                        <span className="d-block mb-1">Evang. Didi Blessing Ezenwafor</span>
                        <small className="h6 text-muted">Administration and Communication</small>
                      </a></h5>
                      <div className="mt-3">
                        <Button
                          className="btn-icon-only rounded-circle"
                          color="danger"
                          href="https://www.instagram.com/blessingpraises/"
                          target="_blank"
                          
                        >
                          <i className="fa fa-instagram" />
                        </Button>
                        <Button
                          className="btn-icon-only rounded-circle ml-1"
                          color="danger"
                          href="https://www.facebook.com/people/Didi-Blessing/100009065027743"
                          target="_blank"
                        >
                          <i className="fa fa-facebook" />
                        </Button>
                        
                      </div>
                    </div>
                  </div>
                </Col>

                <Col className="mb-5 mb-lg-0" lg="3" md="6">
                  <div className="px-4">
                    <img
                      alt="..."
                      className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                      src={require("assets/img/chidera.jpg")}
                      style={{ width: "200px" }}
                    />
                    <div className="pt-4 text-center">
                      <h5 className="title">
                      <a href="\secretary">
                        <span className="d-block mb-1">Barr. Chidera Nkonyel Ezenwafor</span>
                        <small className="h6 text-muted">Secretary</small>
                      </a></h5>
                      <div className="mt-3">
                        <Button
                          className="btn-icon-only rounded-circle"
                          color="success"
                          href="https://www.instagram.com/cherish_mica/"
                          target="_blank"
                        >
                          <i className="fa fa-instagram" />
                        </Button>
                        <Button
                          className="btn-icon-only rounded-circle ml-1"
                          color="success"
                          target="_blank"
                          href="https://www.facebook.com/cherishezenwafor"
                        >
                          <i className="fa fa-facebook" />
                        </Button>
                        
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="section section-lg pt-0">
            <Container>
              <Card className="bg-gradient-warning shadow-lg border-0">
                <div className="p-5">
                <h3 className="text-white">
                     Our Progress
                  </h3>
                  <p className="lead text-white mt-3">
                    Since the year 2010, Emma and Grace Education Foundation
                     has been offering scholarships and financial aid to students to pursue
                    University degrees thereby shaping and impacting their futures.
                  </p>
                  <Row className="align-items-center">
                    <Col className="ml-lg-auto" lg="3">
                    <h1 className="text-white">22+</h1>
                    <h3 className="text-white"> University Graduates</h3>
                    </Col>
                    <Col className="ml-lg-auto" lg="3">
                    <h1 className="text-white">6+</h1>
                    <h3 className="text-white">O'level Scholarships</h3>
                    </Col>
                    <Col className="ml-lg-auto" lg="3">
                    <h1 className="text-white">25+</h1>
                    <h3 className="text-white"> University Undergrads</h3>
                    </Col>
                    <Col className="ml-lg-auto" lg="3">
                    <h1 className="text-white">300+</h1>
                    <h3 className="text-white">Supported Widows</h3>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Container>
          </section>
          <section className="section section-lg bg-gradient-default">
            <Container className="pt-lg pb-100">
              <Row className="text-center justify-content-center">
                <Col lg="10">
                  <h2 className="display-3 text-white">Want to work with us?</h2>
                  <p className="lead text-white">
                  Your input and support is very important to us. 
                  Partner with us and help to make a difference!
                  </p><br /><br />
                  <p className="lead text-white">
                  The 4 easy steps it takes to Sponsor a Child and Change Lives 
                  </p>
                  
                </Col>
              </Row>
              <Row className="row-grid mt-5">
                <Col lg="3">
                  <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                    <i className="ni ni-bell-55 text-primary" />
                  </div>
                  <h5 className="text-white mt-3">We identify the student</h5>
                  <p className="text-white mt-3">
                  Academic excellence & proof of inability to pay fees 
                  are two key factors we consider in picking a beneficiary
                  </p>
                </Col>
                <Col lg="3">
                  <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                    <i className="ni ni-ruler-pencil text-primary" />
                  </div>
                  <h5 className="text-white mt-3">Admission</h5>
                  <p className="text-white mt-3">
                  We counsel the student, pay tuition fees with other expenses (accommodation, feeding etc.) and provide mentorship for student
                  </p>
                </Col>
                <Col lg="3">
                  <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                    <i className="ni ni-atom text-primary" />
                  </div>
                  <h5 className="text-white mt-3">Graduation</h5>
                  <p className="text-white mt-3">
                  A time to celebrate and be glad we all contributed to this light shining
                  </p>
                </Col>

                <Col lg="3">
                  <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                    <i className="ni ni-diamond text-primary" />
                  </div>
                  <h5 className="text-white mt-3">Mentorship</h5>
                  <p className="text-white mt-3">
                  Don't just train the child, help them get going afterwards
                  </p>
                </Col>
              </Row>
            </Container>
           
          </section>

          <section className="section pb-0 bg-gradient-info">
            <Container className="pb-300"> 
            <h2 className="display-3 text-white">SCHOLARSHIP AWARDEES TESTIMONIALS</h2>
            <h5 className="title text-white">“Emma and Grace Foundation takes care of our accommodation, school fees and welfare. It is a marvellous thing”.</h5>
            <Scroll>
            <div style={{display:"flex", paddingTop:"20px"}}>
            <div>
                <div style={{height:'680px', width:'400px'}} className= 'dib br3 pa3 ma2 grow shadow-2 bg-gradient-white'>
                        
                      <div className="text-center">
                          <img
                            alt="..."
                            className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                            src={require("assets/img/chisom.png")}
                            style={{ width: "100px" }}
                          />
                           <h4 className="title text-info">
                            Pioneer Graduate
                          </h4>
                          <h6 className="title text-info">Mrs Chisom Okoroji</h6>
                          <p className="title text-info">University of Nigeria / Biochemistry</p>
                        </div>
                        <div className="pl-2">
                          <p>
                            Evang. Iwuchukwu Ezenwafor is actually my distant uncle. 
                            I heard about the foundation when i had just got admission into UNN. 
                            And he called me and said, "You have been doing fine. 
                            I think i will start paying for your school fees" and that was it. 
                            He was so impressed when i came back with my first result. 
                            In the next 10 years, i see a lot of professionals emerging out of this scholarship program. 
                            The anniversary event has been an awesome one.
                             I wasn't expecting to see this whole lot of people attending this event. 
                             I had thought that it was only for the youths, but i am surprised by the large turnout at the event. 
                             And i believe that this is a great inspiration for other sons and daughters of Uga town to emulate the evangelist.
                          </p>
                        </div>
                    </div> 
              </div>

              <div>
                <div style={{height:'500px', width:'400px'}} className= 'dib br3 pa3 ma2 grow shadow-2 bg-gradient-white'>
                  
                  <div className="text-center">
                          <img
                            alt="..."
                            className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                            src={require("assets/img/chimezie.jpg")}
                            style={{ width: "100px" }}
                          />
                           <h4 className="title text-info">
                            Graduate
                          </h4>
                          <h6 className="title text-info">Mr Chimezie Emmanuel Obasi</h6>
                          <p className="title text-info">Nnamdi Azikiwe University</p>
                      </div><br />
                      <div className="pl-2">
                          <p>
                            Actually it was by the grace of God that I was selected, so I
                            really appreciate God for this. I still want to thank the
                            founder, our lovely daddy. He is just like a father to us. He
                            has done what many have not been able to do for this
                            community. He takes care of our accommodation, school
                            fees and welfare. It is a marvellous thing.
                           </p>
                        </div>
                    </div> 
              </div>

              <div>
                <div style={{height:'600px', width:'400px'}} className= 'dib br3 pa3 ma2 grow shadow-2 bg-gradient-white'>
                  
                <div className="text-center">
                          <img
                            alt="..."
                            className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                            src={require("assets/img/emmanuel.png")}
                            style={{ width: "100px" }}
                          />
                           <h4 className="title text-info">
                            Undergraduate
                          </h4>
                          <h6 className="title text-info">Mr Emmanuel Ifeanyi Eboh</h6>
                          <p className="title text-info">University of Ibadan / Medicine</p>
                        </div>
                        <div className="pl-2">
                          <p>
                            As one of the beneficiaries of Emma and Grace Education Foundation, 
                            the 6 anniversary of the organization gives us a wonderful opportunity to thank our benefactor, 
                            his wife and children. To our daddy, I want to assure you that all you have done for us will never be invain. 
                            God has put a burden in my heart to propagate this foundation and to make a special contribution towards it in the future. 
                            Based on what Evangelist Iwuchukwu Ezenwafor has done to empower the youths of Uga, 
                            I believe that, in the next 10 years, our community will be used as an example for other communities to emulate.
                           </p>
                        </div>
                    </div> 
              </div>

              <div>
                <div style={{height:'500px', width:'400px'}} className= 'dib br3 pa3 ma2 grow shadow-2 bg-gradient-white'>
                <div className="text-center">
                          <img
                            alt="..."
                            className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                            src={require("assets/img/nicholas.png")}
                            style={{ width: "100px" }}
                          />
                           <h4 className="title text-info">
                            Graduate
                          </h4>
                          <h6 className="title text-info">Mr Nicholas Ezeanokete</h6>
                          <p className="title text-info">University of Calabar / Medical Lab Science</p>
                        </div><br />
                        <div className="pl-2">
                          <p>
                           I am very happy to be one of the beneficiaries of this foundation. 
                           Evangelist Ezenwanfor is a very good man and he has been doing this for the past 6 years. 
                           I am very happy, and my prayer is that God will bless him and grant him his wishes.
                           </p>
                        </div>
                    </div> 
              </div>

              <div>
                <div style={{height:'540px', width:'400px'}} className= 'dib br3 pa3 ma2 grow shadow-2 bg-gradient-white'>
                <div className="text-center">
                          <img
                            alt="..."
                            className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                            src={require("assets/img/lucy.png")}
                            style={{ width: "100px" }}
                          />
                           <h4 className="title text-info">
                            Graduate
                          </h4>
                          <h6 className="title text-info">Miss Lucy Chidiogo Ezeonyejekwe</h6>
                          <p className="title text-info">Ojukwu University / Microbiology</p>
                        </div>
                        <div className="pl-2">
                          <p>
                            I thank God for the opportunity to participate in this 6th year anniversary of 
                            Emma and Grace education Foundation. We have people here who have benefitted from 
                            this foundation, ensuring that their education is taken to the maximum level. 
                            We also have widows who are beneficiaries. The best thing you can give to anyone 
                            is to show them love, and this is what the foundation has given to us. 
                            I praise God for the scholarship and I thank the Ezenwafors for their generosity 
                            and kindness.
                           </p>
                        </div>
                    </div> 
              </div>
              </div>
              </Scroll>
              <br /><br />
            </Container>
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>



          <section className="section section-lg pt-lg-100 section-contact-us">
            <Container>
              <Row className="justify-content-center mt--300">
                <Col lg="8">
                  <Card className="bg-gradient-secondary shadow">
                    <CardBody className="p-lg-5">
                      <h4 className="mb-1">Want to work with us?</h4>
                      <p className="mt-0">
                        Your project is very important to us.
                      </p><br />
                      <p>Send us a message today, we look forward to hearing from you soon!</p>
                      <div>
                        <Button
                          block
                          className="btn-round"
                          color="default"
                          size="lg"
                          type="button"
                          target="_blank"
                          href="mailto:info@emmaandgracefoundation.org"
                        >
                          Send Message
                        </Button>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        <SimpleFooter />
      </>
    );
  }
}

export default Landing;
